import React from 'react';

import './StepVisual.scss';
import StepVisualImage from '../../assets/images/otp-banner.jpg';

const StepVisual = () => {
  return (
    <div className="StepVisual">
      {/* <h2 className="StepVisual__title">
        Grab the license
        <br /> and start driving now
      </h2> */}
      <img alt="" src={StepVisualImage} className="StepVisual__image" />
    </div>
  );
};

export default StepVisual;
