import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './PromoCode.scss';

interface CustomProps {
  promotion?: any;
  course?: any;
  amount?: number;
  removeButtonText?: string;
  showRemoveButton?: boolean;
  onChange: () => void;
  showPromoCodeBox?: boolean;
}

interface CustomState {
  token: string | null;
}

// Combine CustomProps and RouteComponentProps into a single Props type
type Props = CustomProps & RouteComponentProps;

class PromoCode extends Component<Props, CustomState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      token: null,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (token) {
      this.setState({
        token,
      });
    }
  }

  onClick() {
    this.props.onChange();
  }

  renderPromoCodeText() {
    const { search } = this.props.location;
    
    

    return (
      <div className="ApplyCoupon__text">
       
          <span className="ApplyCoupon__title">
            {this.props.promotion
              ? this.props.promotion.publicPromoCode
              : 'PROMOTION'}
          </span>
      

        {this.props.amount !== 0 && (
          <div className="ApplyCoupon__subtitle">AED {this.props.amount?.toFixed(2)}</div>
        )}
      </div>
    );
  }

  renderRemoveButton() {
    return (
      <div className="ApplyCoupon__btn">
        <button
          type="button"
          className="Button Button__link"
          onClick={() => this.onClick()}
        >
          {this.props.removeButtonText || 'Remove'}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="ApplyCoupon">
        <div className="ApplyCoupon__content">
          <div className="ApplyCoupon__icon">
            <span className="icon-promocode">
              <span className="path1" />
              <span className="path2" />
            </span>
          </div>
          {this.renderPromoCodeText()}
        </div>
        {this.props.showRemoveButton && this.renderRemoveButton()}
      </div>
    );
  }
}

// Wrap PromoCode with withRouter to inject routing props
export default withRouter(PromoCode);
