/* eslint jsx-a11y/anchor-is-valid: "off" */
import React from 'react';

import './UserHeader.scss';
import Logo from '../../components/logo/Logo';
import UserHeaderNotifications from './Notifications';
import HeaderLanguageSelection from './LanguageSelection';
import LogoutButton from '../../components/app/logout-button';

const UserHeaderCanvas: React.SFC = () => {
  return (
    <header className="UserHeader">
      <div className="UserHeader__container">
        <div className="UserHeaderRow">
          <div className="UserHeader__col UserHeader__col--auto">
            <Logo />
          </div>
          <div className="UserHeader__col UserHeader__col--auto">
            <div className="UserHeaderRow">
              <div className="UserHeader__col UserHeader__col--auto">
                <UserHeaderNotifications />
              </div>
              <div className="UserHeader__col UserHeader__col--auto UserHeader__Logout">
                <LogoutButton />
              </div>
              <div className="UserHeader__col UserHeader__col--auto">
                <HeaderLanguageSelection currentLanguage={'en'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

// UserHeaderCanvas.propTypes = {
//   user: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     image: PropTypes.string,
//   }),
//   logoutUser: PropTypes.func.isRequired,
// };

export default UserHeaderCanvas;
