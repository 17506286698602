import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
// import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import '../Input.scss';
import './DateInput.scss';

interface CustomProps {
  default?: string | Date;
  placeholder?: string;
  onChange: (value: Date) => void;
  gap?: 'sm' | 'md' | 'lg';
  name?: string;
  label?: string;
  error?: string;
  minDate?: Date;
  maxDate?: Date;
  filterDate?: (date: Date) => boolean;
  disabled?: boolean;
}

interface CustomState {
  value?: Date;
}

class DateInput extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    gap: 'md',
    name: '',
    label: '',
    error: '',
    placeholder: '',
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: this.props.default ? new Date(this.props.default) : undefined,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: CustomProps) {
    // console.log(prevProps.default, this.props.default);
    if (this.props.default && prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    this.setState({ value: new Date(this.props.default || '') });
  }

  handleChange(date: Date) {
    this.setState({
      value: date,
    });
    // console.log(date);
    this.props.onChange(date);
  }

  render() {
    const {
      name,
      label,
      placeholder,
      gap,
      error,
      minDate,
      maxDate,
      filterDate,
      disabled,
    } = this.props;
    const inputIdCandidate = name || label || placeholder || '';
    const inputId = inputIdCandidate
      .toLowerCase()
      .split(' ')
      .join('-');
    return (
      <div className={`Input Input--gap-${gap}`}>
        {label && (
          <label htmlFor={inputId} className="Input__label Input__label--full">
            {label}
          </label>
        )}

        <DatePicker
          // className="Input__field"
          id={inputId}
          selected={this.state.value}
          onChange={this.handleChange}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText={placeholder}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd/MM/yyyy"
          customInput={<CustomDateInput />}
          filterDate={filterDate}
          disabled={disabled}
        />
        {/* <i className="icon-calendar" /> */}
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

// DateInput.propTypes = {
//   default: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
//   placeholder: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   name: PropTypes.string,
//   label: PropTypes.string,
//   error: PropTypes.string,
//   minDate: PropTypes.instanceOf(Date),
//   maxDate: PropTypes.instanceOf(Date),
// };

// DateInput.defaultProps = {
//   gap: 'md',
//   default: null,
//   name: '',
//   label: '',
//   error: '',
//   placeholder: '',
//   minDate: null,
//   maxDate: null,
// };

export default DateInput;

// using forwardRef for this issue:
// https://github.com/Hacker0x01/react-datepicker/issues/862
const CustomDateInput = forwardRef((props, ref) => {
  const { id, placeholder, value, onClick }: { [key: string]: any } = props;
  return (
    <div>
      <input
        id={id}
        style={{zIndex:0}}
        className="Input__field Input__field--icon"
        placeholder={placeholder}
        onClick={onClick}
        value={value}
        readOnly
      />
      <i className="icon-calendar" />
    </div>
  );
});

// CustomDateInput.propTypes = {
//   id: PropTypes.string.is,
//   placeholder: PropTypes.string,
//   value: PropTypes.string,
//   onClick: PropTypes.func,
// };

// CustomDateInput.defaultProps = {
//   placeholder: '',
//   value: null,
//   id: '',
//   onClick: () => {},
// };
