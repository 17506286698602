// import * as UserApi from '../api/user';

// import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';

import api from '../../api';
import errorHandler from '../../error-handler';
import { types as commonTypes } from './common';
import User from '../../models/user';
import { toastr } from 'react-redux-toastr';
import { AxiosError } from 'axios';

// action types
export const types = {
  // signup
  SIGNUP_REQUEST: 'AUTH/SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'AUTH/SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'AUTH/SIGNUP_ERROR',

  // OTP
  SEND_OTP_REQUEST: 'AUTH/SEND_OTP_REQUEST',
  SEND_OTP_SUCCESS: 'AUTH/SEND_OTP_SUCCESS',
  SEND_OTP_ERROR: 'AUTH/SEND_OTP_ERROR',

  // login
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_ERROR: 'AUTH/LOGIN_ERROR',

  // forgot password
  FORGOT_PASSWORD_REQUEST: 'AUTH/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'AUTH/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'AUTH/FORGOT_PASSWORD_ERROR',

  // reset password with OTP
  RESET_PASSWORD_OTP_REQUEST: 'AUTH/RESET_PASSWORD_OTP_REQUEST',
  RESET_PASSWORD_OTP_SUCCESS: 'AUTH/RESET_PASSWORD_OTP_SUCCESS',
  RESET_PASSWORD_OTP_ERROR: 'AUTH/RESET_PASSWORD_OTP_ERROR',

  // logout
  LOGOUT: 'AUTH/LOGOUT',
  // redirect
  REDIRECT_SAVE: 'AUTH/REDIRECT_SAVE',
  REDIRECT_APPLY: 'AUTH/REDIRECT_APPLY',

  // login
  VERIFY_LECTURE_OTP_REQUEST: 'AUTH/VERIFY_LECTURE_OTP_REQUEST',
  VERIFY_LECTURE_OTP_SUCCESS: 'AUTH/VERIFY_LECTURE_OTP_SUCCESS',
  VERIFY_LECTURE_OTP_ERROR: 'AUTH/VERIFY_LECTURE_OTP_ERROR',

  // OTP
  SEND_LECTURE_OTP_REQUEST: 'AUTH/SEND_LECTURE_OTP_REQUEST',
  SEND_LECTURE_OTP_SUCCESS: 'AUTH/SEND_LECTURE_OTP_SUCCESS',
  SEND_LECTURE_OTP_ERROR: 'AUTH/SEND_LECTURE_OTP_ERROR',

  // Update Lecture Language
  UPDATE_LECTURE_LANGUAGE_REQUEST: 'AUTH/UPDATE_LECTURE_LANGUAGE_REQUEST',
  UPDATE_LECTURE_LANGUAGE_SUCCESS: 'AUTH/UPDATE_LECTURE_LANGUAGE_SUCCESS',
  UPDATE_LECTURE_LANGUAGE_ERROR: 'AUTH/UPDATE_LECTURE_LANGUAGE_ERROR',

  // Book Appointment
  APPOINTMENT_REQUEST: 'AUTH/APPOINTMENT_REQUEST',
  APPOINTMENT_SUCCESS: 'AUTH/APPOINTMENT_SUCCESS',
  APPOINTMENT_ERROR: 'AUTH/APPOINTMENT_ERROR',

  // Customer Feedback
  FEEDBACK_REQUEST: 'AUTH/FEEDBACK_REQUEST',
  FEEDBACK_SUCCESS: 'AUTH/FEEDBACK_SUCCESS',
  FEEDBACK_ERROR: 'AUTH/FEEDBACK_ERROR',

  // Reset Error Messages
  RESET_ERROR_MESSAGE: 'AUTH/RESET_ERROR_MESSAGE',

  // Resend OTP Request
  RESEND_OTP_REQUEST: 'AUTH/RESEND_OTP_REQUEST',
  RESEND_OTP_SUCCESS: 'AUTH/RESEND_OTP_SUCCESS',
  RESEND_OTP_ERROR: 'AUTH/RESEND_OTP_ERROR'
};

export interface AuthState {
  isLoading: boolean;
  verify?: any;
  token?: string;
  user?: User;
  redirectTo: string;
  userProfileLoaded: boolean;
  isVerifyLoading?: boolean;
  countryCode?: string;
  startingCode?: string;
  phoneNumber?: string;
  loginError?: string;
  forgotPassword: ForgotPasswordState;
  otpExpiry?: Date;
  otpExpiryDate?: Date;
  isSendOtpLoading?: boolean;
  theoryLectureVisibilityExpiry?: Date;
  isLanguageUpdateLoading?: boolean;
  mobileNumber?: string;
  isAppointmentLoading?: boolean;
  appointmentBooked?: boolean;
  appointmentError?: string;
  isDisabledFeedbackSubmit?: boolean;
  feedbackError?: string;
}

interface ForgotPasswordState {
  id?: string;
  error?: string;
}

// initial state
const initialState: AuthState = {
  isLoading: false,
  redirectTo: '/',
  userProfileLoaded: false,
  forgotPassword: {},
};

// reducer
export default (state: AuthState = initialState, action: any): AuthState => {
  switch (action.type) {
    // signup
    case types.SIGNUP_REQUEST:
      return { ...state, isLoading: true };
    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verify: action.data,
      };
    case types.SIGNUP_ERROR:
      return { ...state, isLoading: false };

    // profile
    case commonTypes.FETCH_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case commonTypes.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.data,
        userProfileLoaded: !!action.data,
      };
    case commonTypes.FETCH_PROFILE_ERROR:
      return { ...state, isLoading: false };

    // otp
    case types.SEND_OTP_REQUEST:
      return { ...state, isLoading: true };
    case types.SEND_OTP_SUCCESS:
      return { ...state, isLoading: false, verify: action.data };
    case types.SEND_OTP_ERROR:
      return { ...state, isLoading: false };

    // login
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        countryCode: action.data ? action.data.countryCode : state.countryCode,
        startingCode: action.data
          ? action.data.startingCode
          : state.startingCode,
        phoneNumber: action.data ? action.data.phoneNumber : state.phoneNumber,
        loginError: undefined,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verify: null,
        // user: action.data.user,
        userProfileLoaded: false,
        token: action.data.token,
        loginError: undefined,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        user: undefined,
        token: undefined,
        userProfileLoaded: false,
        loginError: action.data ? action.data.errorMessage : undefined,
      };
    // forgot password
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        countryCode: action.data ? action.data.countryCode : state.countryCode,
        startingCode: action.data
          ? action.data.startingCode
          : state.startingCode,
        phoneNumber: action.data ? action.data.phoneNumber : state.phoneNumber,
        forgotPassword: {
          ...state.forgotPassword,
          error: undefined,
        },
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        forgotPassword: {
          ...state.forgotPassword,
          id: action.data.id,
          error: undefined,
        },
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        forgotPassword: {
          ...state.forgotPassword,
          error: action.data.errorMessage,
        },
      };
    // reset password
    case types.RESET_PASSWORD_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        forgotPassword: {
          ...state.forgotPassword,
          error: undefined,
        },
      };
    case types.RESET_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        forgotPassword: {
          ...state.forgotPassword,
          id: undefined,
          error: undefined,
        },
      };
    case types.RESET_PASSWORD_OTP_ERROR:
      return {
        ...state,
        isLoading: false,
        forgotPassword: {
          ...state.forgotPassword,
          error: action.data.errorMessage,
        },
      };

    // logout
    case types.LOGOUT:
      api.setToken(undefined);
      return {
        ...state,
        user: undefined,
        token: undefined,
        userProfileLoaded: false,
      };
    case commonTypes.RESET_DATA:
      return {
        ...initialState,
        countryCode: state.countryCode,
        startingCode: state.startingCode,
        phoneNumber: state.phoneNumber,
      };

    // redirect
    case types.REDIRECT_SAVE:
      return { ...state, redirectTo: action.data };
    case types.REDIRECT_APPLY:
      return { ...state, redirectTo: '/' };
    // login
    case types.VERIFY_LECTURE_OTP_REQUEST:
      return {
        ...state,
        isVerifyLoading: true,
      };
    case types.VERIFY_LECTURE_OTP_SUCCESS:
      return {
        ...state,
        user: action.data,
        userProfileLoaded: !!action.data,
        isVerifyLoading: false,
        theoryLectureVisibilityExpiry:
          action.data && action.data.StudentCourse
            ? action.data.StudentCourse.theoryLectureVisibilityExpiry
            : null,
      };
    case types.VERIFY_LECTURE_OTP_ERROR:
      return {
        ...state,
        isVerifyLoading: false,
      };
    // otp
    case types.SEND_LECTURE_OTP_REQUEST:
      return { ...state, isSendOtpLoading: true };
    case types.SEND_LECTURE_OTP_SUCCESS:
      const myDate = new Date();
      const otpExpiry = myDate.setUTCMinutes(myDate.getUTCMinutes() + 5);
      return {
        ...state,
        isSendOtpLoading: false,
        otpExpiry: action.data.otpExpiry,
        otpExpiryDate: new Date(otpExpiry),
      };
    case types.SEND_LECTURE_OTP_ERROR:
      return { ...state, isSendOtpLoading: false };

    // Lecture Laguage Updae
    case types.UPDATE_LECTURE_LANGUAGE_REQUEST:
      return { ...state, isLanguageUpdateLoading: true };
    case types.UPDATE_LECTURE_LANGUAGE_SUCCESS:
      return {
        ...state,
        isLanguageUpdateLoading: false,
        user: action.data,
      };
    case types.UPDATE_LECTURE_LANGUAGE_ERROR:
      return { ...state, isLanguageUpdateLoading: false };

    // BookAppointment
    case types.APPOINTMENT_REQUEST:
      return {
        ...state,
        isAppointmentLoading: true,
        countryCode: action.data ? action.data.countryCode : state.countryCode,
        startingCode: action.data
          ? action.data.startingCode
          : state.startingCode,
        mobileNumber: action.data
          ? action.data.mobileNumber
          : state.mobileNumber,
        appointmentError: undefined,
      };
    case types.APPOINTMENT_SUCCESS:
      return {
        ...state,
        isAppointmentLoading: false,
        appointmentBooked: true,
        appointmentError: undefined,
      };
    case types.APPOINTMENT_ERROR:
      return {
        ...state,
        isAppointmentLoading: false,
        appointmentBooked: false,
        appointmentError: action.data ? action.data.errorMessage : undefined,
      };

    // Customer Feedback
    case types.FEEDBACK_REQUEST:
      return {
        ...state,
        isDisabledFeedbackSubmit: true,
        countryCode: action.data ? action.data.countryCode : state.countryCode,
        startingCode: action.data
          ? action.data.startingCode
          : state.startingCode,
        mobileNumber: action.data
          ? action.data.mobileNumber
          : state.mobileNumber,
        feedbackError: undefined,
      };
    case types.FEEDBACK_SUCCESS:
      return {
        ...state,
        isDisabledFeedbackSubmit: false,
        feedbackError: undefined,
      };
    case types.FEEDBACK_ERROR:
      return {
        ...state,
        isDisabledFeedbackSubmit: false,
        feedbackError: action.data ? action.data.errorMessage : undefined,
      };
    case types.RESET_ERROR_MESSAGE:
      return {
        ...state,
        loginError:"",
        forgotPassword:{
          ...state.forgotPassword,
          error:undefined
        }
      }
    // Resend OTP for reset password
    case types.RESEND_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        forgotPassword: {
          ...state.forgotPassword,
          error: undefined,
        },
      };
    case types.RESEND_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        forgotPassword: {
          ...state.forgotPassword,
          id: action.data.id,
          error: undefined,
        },
      };
    case types.RESEND_OTP_ERROR:
      return {
        ...state,
        isLoading: false,
        forgotPassword: {
          ...state.forgotPassword,
          error: action.data.errorMessage,
        },
      };    

    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  signup: (signupData: any) => async (dispatch: Dispatch) => {
    dispatch({ type: types.SIGNUP_REQUEST });
    try {
      const response = await api.user.signup(signupData);
      dispatch({
        type: types.SIGNUP_SUCCESS,
        data: response.data.user,
      });
    } catch (err) {
      const error = err as AxiosError;
      // console.log(error.response.data);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({ type: types.SIGNUP_ERROR, data: error?.response?.data?.errors||'Some thing went wrong' });
      // toastr.error('Error', error.response.data.message);
      errorHandler(error);
      throw error;
    }
  },

  sendOTP: (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.SEND_OTP_REQUEST });
    try {
      const response = await api.user.sendOTP(id);
      dispatch({
        type: types.SEND_OTP_SUCCESS,
        data: response.data.data,
      });
    } catch (error) {
      dispatch({ type: types.SEND_OTP_ERROR });
      errorHandler(error);
      throw error;
    }
  },

  resetPasswordWithOTP: (
    data: {
      id: string;
      otp: string;
      password: string;
    },
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.RESET_PASSWORD_OTP_REQUEST,
    });
    // console.log('credentials: userredentials);
    try {
      const response = await api.user.resetForgotPasswordWithOtp({
        id: data.id,
        otp: data.otp,
        password: data.password,
      });
      // console.log('response(success): ', response);
      dispatch({ type: types.RESET_PASSWORD_OTP_SUCCESS, data: response.data });
      if (onSuccess) onSuccess();
      toastr.success('Success', response.data.message);
    } catch (err) {
      const error = err as AxiosError;
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.RESET_PASSWORD_OTP_ERROR,
        data: {
          errorMessage:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : 'Unable to reset password!',
        },
      });

      // throw error;
    }
  },
  login: (
    data: {
      countryCode: string;
      startingCode: string;
      phoneNumber: string;
      password: string;
      verifiedToken: string;
    },
    onSuccess?: () => void,
    onError?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST,
      data: {
        phoneNumber: data.phoneNumber,
        countryCode: data.countryCode,
        startingCode: data.startingCode,
      },
    });
    // console.log('credentials: userredentials);
    try {
      const response = await api.user.checkUserLogin({
        username: `${data.countryCode}${data.startingCode}${data.phoneNumber}`,
        password: data.password,
        verifiedToken: data.verifiedToken
      });
      const roles =
        response.data && response.data.user ? response.data.user.roles : null;
      if (roles && roles.length && !roles.includes('student')) {
        dispatch({
          type: types.LOGIN_ERROR,
          data: { errorMessage: 'Invalid Username or Password!' },
        });
      } else {
        dispatch({ type: types.LOGIN_SUCCESS, data: response.data });
        api.setToken(response.data.token);
        if (onSuccess) onSuccess();
      }
    } catch (err) {
      const error = err as AxiosError;
      dispatch({
        type: types.LOGIN_ERROR,
        data: { errorMessage: error?.response?.data?.message||'Invalid Credentials' },
      });
      if(error?.response?.data?.data?.response?.error === 'verfification failed'){
        if(onError) onError()
      }
    }
  },
  requestForgotPasswordLink: (
    data: {
      countryCode: string;
      startingCode: string;
      phoneNumber: string;
      verifiedToken: string
    },
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.FORGOT_PASSWORD_REQUEST,
      data: {
        phoneNumber: data.phoneNumber,
        countryCode: data.countryCode,
        startingCode: data.startingCode,
      },
    });
    // console.log('credentials: userredentials);
    try {
      const response = await api.user.requestForgotPasswordLink({
        phone: `${data.countryCode}${data.startingCode}${data.phoneNumber}`,
        role: 'student',
        verifiedToken: data.verifiedToken
      });
      // console.log('response(success): ', response);
      dispatch({
        type: types.FORGOT_PASSWORD_SUCCESS,
        data: response.data.data,
      });
      if (onSuccess) onSuccess();
      toastr.success('Success', response.data.message);
    } catch (err) {
      const error = err as AxiosError;
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : 'Unable to send reset password OTP!';
      dispatch({
        type: types.FORGOT_PASSWORD_ERROR,
        data: {
          errorMessage,
        },
      });

      if (onError) {
        onError(errorMessage);
      }

      // toastr.error('Error', error.response.data.message);
      // throw error;
    }
  },

  loginWithOTP: (
    credentials: { id: string; otp: string },
    onSuccess: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.LOGIN_REQUEST });
    try {
      const response = await api.user.loginWithOTP(credentials);
      // console.log('response: ', response);
      dispatch({ type: types.LOGIN_SUCCESS, data: response.data });
      // toastr.success('Success', 'You are now Logged In!');
      api.setToken(response.data.token);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.LOGIN_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      throw error;
    }
  },

  logout: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.LOGOUT });
  },

  redirectSave: (to: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.REDIRECT_SAVE, data: to });
  },

  redirectApply: () => (dispatch: Dispatch) =>
    dispatch({ type: types.REDIRECT_APPLY }),

  fetchProfile: (onSuccess?: () => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();
    if (!state.authUser.token) {
      return;
    }
    api.setToken(state.authUser.token);
    // dispatch({ type: types.FETCH_PROFILE_REQUEST });
    try {
      const response = await api.user.getStudentProfile();
      const { data } = response;
      dispatch({ type: commonTypes.FETCH_PROFILE_SUCCESS, data });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: commonTypes.FETCH_PROFILE_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  verifyLectureWithOTP: (credentials: { otp: string }) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.VERIFY_LECTURE_OTP_REQUEST });
    try {
      const response = await api.user.verifyLectureWithOTP(credentials);
      dispatch({ type: types.VERIFY_LECTURE_OTP_SUCCESS, data: response.data });
    } catch (error) {
      dispatch({ type: types.VERIFY_LECTURE_OTP_ERROR });
      throw error;
    }
  },
  sendLectureOTP: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.SEND_LECTURE_OTP_REQUEST });
    try {
      const response = await api.user.sendLectureOTP();
      dispatch({
        type: types.SEND_LECTURE_OTP_SUCCESS,
        data: response.data.data,
      });
    } catch (error) {
      dispatch({ type: types.SEND_LECTURE_OTP_ERROR });
      errorHandler(error);
      throw error;
    }
  },
  updateLectureLanguge: (data: any, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.UPDATE_LECTURE_LANGUAGE_REQUEST });
    try {
      const response = await api.user.updateLectureLanguge(data);

      dispatch({
        type: types.UPDATE_LECTURE_LANGUAGE_SUCCESS,
        data: response.data,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_LECTURE_LANGUAGE_ERROR });
      errorHandler(error);
      throw error;
    }
  },
  bookAppointment: (
    data: {
      name: string;
      email?: string;
      mobileNumber: any;
      date: Date;
      time: string;
      purpose: string;
      otherPurpose: string;
    },
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.APPOINTMENT_REQUEST,
      data: {
        mobileNumber: data.mobileNumber.number,
        countryCode: data.mobileNumber.number.countryCode,
        startingCode: data.mobileNumber.number.startingCode,
      },
    });
    // console.log('credentials: userredentials);
    try {
      const response = await api.user.bookAppointment(data);

      dispatch({ type: types.APPOINTMENT_SUCCESS, data: response.data });

      if (onSuccess) onSuccess();
    } catch (err) {
      const error = err as AxiosError;
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({
        type: types.APPOINTMENT_ERROR,
        data: { errorMessage: 'Try again' },
      });
      toastr.error('Error', error?.response?.data?.message||'Something went wrong');

      // toastr.error('Error', error.response.data.message);
      // throw error;
    }
  },

  // customer feedback
  postCustomerFeedback: (
    data: {
      name: string;
      mobileNumber: any;
      complaintTypeId: string;
      complaint: string;
    },
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.FEEDBACK_REQUEST,
      data: {
        mobileNumber: data.mobileNumber.number,
        countryCode: data.mobileNumber.number.countryCode,
        startingCode: data.mobileNumber.number.startingCode,
      },
    });
    try {
      const response = await api.user.postCustomerFeedback(data);

      dispatch({ type: types.FEEDBACK_SUCCESS, data: response.data });

      if (onSuccess) onSuccess();
    } catch (err) {
      const error = err as AxiosError;
      dispatch({
        type: types.FEEDBACK_ERROR,
        data: { errorMessage: 'Try again' },
      });
      toastr.error('Error', error?.response?.data?.message||'Something went wrong');
    }
  },

  clearError: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.RESET_ERROR_MESSAGE });
  },

  resendOTP: (id:string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.RESEND_OTP_REQUEST });
    try {
      const response = await api.user.sendOTP(id);
      dispatch({ type: types.RESEND_OTP_SUCCESS, data: response.data.data });
      toastr.success('Success', response.data.message);
    } catch (err) {
      const error = err as AxiosError
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : 'Unable to resend password OTP!';
      toastr.error('Failed', errorMessage);
      dispatch({
        type: types.FORGOT_PASSWORD_ERROR,
        data: {
          errorMessage,
        },
      });
    }
  },
};
