import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import CoursePlan from '../../components/on-boarding/course-plan';
import { actions as registrationActions } from '../../store/reducers/registration';
import { actions as authActions } from '../../store/reducers/authUser';
import CourseTypeLoader from '../../components/on-boarding/course-type-loader';
import User, { StudentCourse } from '../../models/user';

interface CustomProps {
  isLoading?: boolean;
  user?: User;
  courses?: any[];
  loadCourses?: (force: boolean, preApplyPromoCode: boolean, isForRTA: boolean) => void;
  gotoNextBlock?: () => void;
  addSummary?: (key: string, label: string, value: string) => void;
  selectCourse: (
    course: any,
    courseType: string,
    promoCode?: string,
    onSuccess?: () => void
  ) => void;
  logout: () => void;
  hasCourse?: boolean;
}

interface CustomState {
  isExpanded: boolean;
  promoCode: string;
  discountedAmount: number;
  token: string | null; 
}

class CourseType extends Component<CustomProps, CustomState> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isExpanded: false,
      promoCode: '',
      discountedAmount: 0,
      token: null,
    };
    this.onChangeExpanded = this.onChangeExpanded.bind(this);
    this.onCourseSelected = this.onCourseSelected.bind(this);
  }
  getPromoCode(courseCode: string, token: string | null): string {
    if (token) {
      switch (courseCode) {
        case 'LMV-L4':
          return 'RTA-Lumpsum_LMV_Auto-20-Hrs';
        case 'LMV-L3':
          return 'RTA-Lumpsum_LMV_Auto-15-Hrs';
        case 'LMV-L2':
          return 'RTA-Lumpsum_LMV_Auto-10-Hrs';
        case 'LMV-V4':
          return 'RTA-PUBLIC-VIP-20-Hrs';
        case 'LMV-V2':
          return 'RTA-PUBLIC-VIP-10-Hrs';
        case 'LMV-V3':
          return 'RTA-PUBLIC-VIP-15-Hrs';
        case 'LMV-R':
          return 'RTA-REGULAR-PUBLIC';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  componentDidMount() {
   
    // Extract token from URL
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
  
    this.setState({ token }, () => {
      const preApplyPromoCode = !this.state.token; // preApplyPromoCode is false if token exists
      const promoCode = ''; // Initial generic promo code
      const isForRTA  = token ? true : false

      if (this.props.loadCourses) {
        this.props.loadCourses(false, preApplyPromoCode, isForRTA);
      }
    });
  }
  

  onChangeExpanded() {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  }
 

  async onCourseSelected(courseType: string, course: StudentCourse) {
    if (this.props.addSummary) {
      this.props.addSummary('coursetype', 'Your chosen course is', courseType);
      this.props.addSummary(
        'courseprice',
        'Course price is',
        `AED ${course.amount.totalAmount - course.amount.totalDiscount}`
      );
    }

    try {
      if (this.props.selectCourse) {
        const promoCode = this.getPromoCode(course.course.code, this.state.token);
        
        await this.props.selectCourse(course, courseType, promoCode);
      }
      if (this.props.gotoNextBlock) {
        this.props.gotoNextBlock();
      }
    }catch (err) {
      if (err.response.data.message === 'User has already done payment') {
        toastr.error(
          'Error',
          'User has already done payment. Please log in again'
        );
        this.props.logout();
      } else {
        toastr.error('Error', err.response.data.message);
      }
    }
  }

  

  renderCoursePlan(course: any, courseType: string, key: string | number) {
    return (
      <CoursePlan
        key={`${key}`}
        course={course}
        onSelect={() => this.onCourseSelected(courseType, course)}
        isExpanded={this.state.isExpanded}
        onChangeExpanded={this.onChangeExpanded}
      />
    );
  }

  renderLoading() {
    return (
      <>
        <CourseTypeLoader />
        <CourseTypeLoader />
      </>
    );
  }

  renderCourses() {
    return (this.props.courses || []).map((courseData, index) => {
      return this.renderCoursePlan(
        courseData.course,
        courseData.courseType,
        index
      );
    });
  }

  renderNoCourse() {
    return (
      <div className="container">
        <p className="font-lg text-muted mb-5">
          <span className="mr-1 mb-1 d-sm-block">
            Thank you for registering with Excellence Driving Center. Our
            support executive will contact you soon.
          </span>
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="Plan">
        {this.props.isLoading && this.renderLoading()}
        {!this.props.isLoading && this.props.hasCourse && (
          <>
            <div className="row"> {this.renderCourses()}</div>
            <div className="row">
              <p className="Plan__note">
                <sup>**</sup> License is guaranteed within the amount displayed
                above, subject to completion of the course and RTA requirements.
              </p>
            </div>
          </>
        )}
        {!this.props.isLoading &&
          this.props.hasCourse === false &&
          this.renderNoCourse()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isLoading: state.registration.isLoading,
  user: state.registration.user,
  hasCourse: state.registration.isLoading
    ? false
    : state.registration.hasCourse,
  courses:
    state.registration.isLoading || !state.registration.courses
      ? []
      : Object.keys(state.registration.courses).map((kv) => ({
          courseType: kv,
          course: state.registration.courses[kv],
        })),
});

const mapDispatchToProps = (dispatch: any) => ({
  loadCourses: (force: boolean, preApplyPromoCode: boolean, isForRTA: boolean) =>
    dispatch(registrationActions.getCourses(force, preApplyPromoCode, isForRTA)),
  selectCourse: (course: any, courseType: string, promoCode?: string, onSuccess?: () => void) =>
    dispatch(registrationActions.selectCourse(course, courseType, promoCode, onSuccess)),
  logout: () => dispatch(authActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseType);
