/* eslint-disable react/jsx-no-target-blank */
/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import './Footer.scss';

const Footer = () => {
  const domain = 'https://www.excellencedriving.com/en';
  return (
    <footer id="footer" className="footer-txt">
      <div className="container">
        <div className="footer-top">
          <div className="row gutter-10">
            <div className="col-xl-2 col-lg-3 col-sm-4 col-12 mb-lg-0 mb-3">
              <ul className="footer-menu-list m-0 p-0">
                <li>
                  <span> Quick Links</span>
                  <ul>
                    <li>
                      {' '}
                      <a href={`${domain}/who-we-are/`}>Who we are</a>
                    </li>
                    <li className="mega-menu-link">
                      {' '}
                      <a href={`${domain}/contact-us/`}>Contact Us</a>
                    </li>
                    <li className="mega-menu-link">
                      {' '}
                      <a href={`${domain}/faq/`}>FAQ</a>
                    </li>
                    <li>
                      {' '}
                      <a href={`${domain}/term-of-use/`}>Terms of use</a>
                    </li>
                    <li>
                      {' '}
                      <a href={`${domain}/privacy-policy/`}>Privacy Policy</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xl-6 col-lg-5 col-sm-8 col-12 mb-4">
              <div className="pr-xl-5">
                <span className="text-uppercase font-weight-medium mb-3 pb-1 text-body d-block font-base">
                  Address
                </span>
                <div className="address row">
                  <div className="col-sm-6 col-12">
                    <p>
                      Excellence Driving
                      <br />
                      PO Box – 446241
                      <br />
                      Al Qusais Industrial Area – 5<br />
                      Dubai, United Arab Emirates
                    </p>
                  </div>
                  <div className="col-sm-6 col-12">
                    <p className="m-0">
                      <a href="tel:+971 4 3583005" className="footer-txt">
                        Tel : +971 4 3583005
                      </a>
                    </p>
                    <p className="m-0">Fax : +971 4 3967306</p>
                    <p className="m-0">
                      <a
                        href="mailto: info@excellencedriving.com"
                        className="footer-txt"
                      >
                        info@excellencedriving.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-8 col-12 last-box">
              <div className="social-media">
                <span className="text-uppercase font-weight-medium mb-3 pb-1 text-body font-base d-block">
                  Follow Us On
                </span>
                <ul className="list-unstyled social-links list-inline p-0 m-0">
                  <li className="pr-4 mr-2 mb-0">
                    <a
                      href="https://www.facebook.com/Excellence-Driving-Centre-101488924658045/"
                      target="_blank"
                    >
                      <i className="icon-facebook-logo" />
                    </a>
                  </li>
                  <li className="pr-4 mr-2 mb-0">
                    <a href="https://twitter.com/Excellence_DC" target="_blank">
                      <i className="icon-twitter" />
                    </a>
                  </li>
                  <li className="pr-4 mr-2 mb-0">
                    <a
                      href="https://www.instagram.com/excellencedrivingcentre/"
                      target="_blank"
                    >
                      <i className="icon-instagram ml-md-2 m-0" />
                    </a>
                  </li>
                  <li className="mb-0">
                    <a
                      href="https://www.youtube.com/channel/UCgJw-D0rFToygjykyAFsGLQ?view_as=subscriber"
                      target="_blank"
                    >
                      <i className="icon-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-btm border-top">
          <div className="copyright-blk text-center py-4">
            <div className="copy-right pr-4">
              <div className="wrapper">
                {' '}
                <span className="co-rg-txt">
                  © 2019 Excellence Driving Centre.&nbsp; All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
