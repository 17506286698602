import React from 'react';
import Popup from '../../components/popup';
import { Language } from '../../models/language';

interface CustomProps {
  currentLanguage: string;
}

const languages: Language[] = [
  { name: 'English', code: 'en', image: '/images/english.png' },
  // { name: 'Arabic', code: 'ar', image: '/images/arabic.png' },
];
const HeaderLanguageSelection: React.SFC<CustomProps> = ({
  currentLanguage,
}) => {
  const renderLanguage = (language: Language, selected?: boolean) => {
    return (
      <button
        id={selected ? 'chooseLanguage' : ''}
        className="UserHeader__language-chosen"
      >
        <img
          src={language.image}
          className="UserHeader__language-flag UserHeader__language-flag--no-margin"
          alt={language.code}
        />
        {!selected && language.name}
      </button>
    );
  };
  return (
    <div className="UserHeader__option-item UserHeader__language-block">
      {renderLanguage(languages[0], true)}
      <Popup
        target="chooseLanguage"
        cuePos="pos1"
        left={-10}
        align="right"
        right="-5"
      >
        <ul className="UserHeader__languages">
          {languages.map((lng: Language) => (
            <li className="UserHeader__language" key={lng.name}>
              {renderLanguage(lng)}
            </li>
          ))}
        </ul>
      </Popup>
    </div>
  );
};

export default HeaderLanguageSelection;
