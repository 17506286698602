import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import User from '../../models/user';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import Popup from '../../components/popup';
// import Avatar from '../../components/avatar';
import ClickLink from '../../components/click-link';
import { Notification } from '../../models/userNotification';
import { State } from '../../store/interfaces';

import { actions as notificationActions } from '../../store/reducers/notification';
import { withScrollHandler } from '../../components/scroll';
import { func } from 'prop-types';
import { useScrollPosition } from '../../hooks/scroll';
import { ScrollDirection } from '../../models/scroll-directions';

interface CustomProps {
  notifications?: Notification[];
}

const perPage = 5;

const UserHeaderNotifications: React.SFC<CustomProps> = () => {
  const [page, setPage] = useState(1);

  const { isLoading, user } = useSelector((state: State) => state.authUser);

  const { list, unReadCount } = useSelector(
    (state: State) => state.notification
  );

  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    if (!user) return;

    const request = {
      pagination: { perPage, page },
    };
    if (!isLoading) {
      Promise.all([
        dispatch(notificationActions.getList(request)),
        dispatch(notificationActions.getUnreadCount()),
      ]);
    }
  }, [page, user, isLoading]);

  const handleClick = () => {
    if (unReadCount) {
      dispatch(notificationActions.denotify());
    }
  };

  const handleScroll = (direction: ScrollDirection) => {
    if (direction === ScrollDirection.PageDown) {
      if (!isLoading) {
        if (list.length >= page * perPage) setPage(page + 1);
      }
    }
  };
  useScrollPosition(handleScroll, [handleScroll], ref);
  return (
    <div className="UserHeader__option-item UserHeader__notification-block">
      <div className="UserHeader__notification UserHeader__notification--active">
        <button
          id="notificationIcon"
          className="UserHeader__notification-icon"
          onClick={handleClick}
        >
          <i className="icon-notification-fill" />
        </button>
        <Popup
          target="notificationIcon"
          cuePos="pos1"
          align="right"
          right="-20"
        >
          <div className="UserHeader__notification-box" ref={ref}>
            <div className="UserHeader__notification-header">Notifications</div>
            <ul className="UserHeader__notification-list">
              {list &&
                list.map((notification) => (
                  <li
                    className="UserHeader__notification-item"
                    key={notification.timestamp}
                  >
                    <div className="UserHeader__notification-item-icon">
                      <i className="icon-notification-outline"></i>
                    </div>
                    {/* <Avatar
                    src={notification.user.image}
                    name={notification.user.name}
                  /> */}
                    <div className="UserHeader__notification-message">
                      {notification.message}
                      <span className="UserHeader__notification-time">
                        {formatDistanceStrict(
                          new Date(notification.timestamp),
                          new Date()
                        )}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="UserHeader__notification-footer">
              {/* <ClickLink link="" primary>
                View All Notifications
              </ClickLink> */}
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default UserHeaderNotifications;
