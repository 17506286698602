export enum RouteKeys {
  Auth = '/auth',
  Login = '/auth/login',
  Signup = '/signup',
  SignupOtp = '/signup/otp',
  SignupOnboard = '/signup/onboard',
  SignupThankYou = '/signup/thank-you',
  Home = '/',
  Dashboard = '/dashboard',
  ForgotPassword = '/auth/forgot-password',
  ResetPasswordWithOTP = '/auth/forgot-password/otp',
  Payment = '/dashboard/payment',
  PaymentCallback = '/payment/redirect',
  NotFound = '/page-not-found',
  LectureVideo = '/lecture-video/:lessonId/:topicId/:subTopicId',
  LecturePdf = '/lecture-pdf/:lessonId/:topicId/:subTopicId',
  LectureLink = '/lecture-link/:lessonId/:topicId/:subTopicId',
  ScheduleDetails = '/schedules/:scheduleId',
  QuestionAnswer = '/question-answer/:lessonId/:topicId/quiz',
  PdfInvoice = '/invoice/:id/pdf/',
  LumpsumPdfInvoice = '/lumpsum-invoice/pdf/',
  PdfContract = '/contract/:id/pdf/',
  Dev = '/dev/',
  PdfRefundDocument = '/refund-document/:id/pdf/',
  PdfClearanceCertificate = '/clearance-certificate/:id/pdf/',
  BookAppointment = '/book-an-appointment',
  BookAppointmentThankYou = '/book-an-appointment/thank-you',
  PaymentThankYou = '/dashboard/payment/thank-you',
  CustomerFeedback = '/customer-feedback',
  CustomerFeedbackThankYou = '/customer-feedback-thank-you',
  CorporateLumpsumPdfInvoice = '/corporate-lumpsum-invoice/pdf/',
  CorporatePdfInvoice = '/corporate-invoice/pdf/',
  PdfRefundDocumentCorporate = '/corporate-refund-document/:id/pdf/',
  TokenVerifier = '/token-verifier',
  VerifyCoupon ='/verifycoupon',
}
