import React from 'react';
// import PropTypes, { InferProps } from 'prop-types';
import { Link } from 'react-router-dom';

import './Logo.scss';

import logoImageWhite from './logo-white.svg';
import logoImage from './logo.svg';

interface CustomProps {
  size?: 'small' | 'large';
  colored?: boolean;
}

const Logo: React.SFC<CustomProps> = (props: CustomProps) => {
  return (
    <Link to="/" className="Logo__link">
      <img
        className={`Logo Logo--${props.size}`}
        src={props.colored ? logoImage : logoImageWhite}
        alt="EDC"
        title="EDC"
      />
    </Link>
  );
};

// Logo.propTypes = {
//   size: PropTypes.oneOf(['small', 'large']),
// };

Logo.defaultProps = {
  size: 'small',
  colored: false,
};

export default Logo;
