import React, { useState, useEffect } from 'react';
import api from '../../api';
import { FullPageLoader } from '../../components/loader';
import Button from '../../components/button';
import Header from '../header';
import './styles.scss';

const VerifyCoupon = () => {
  const [couponCode, setCouponCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [verificationResult, setVerificationResult] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => {
        setAlertMessage('');
        setAlertType('');
      }, 5000); // Clear after 5 seconds

      return () => clearTimeout(timer); // Cleanup timer if the component unmounts
    }
  }, [alertMessage]);

  const handleCouponCodeChange = (e: any) => {
    setCouponCode(e.target.value);
  };

  const handleVerifyCoupon = async () => {
    if (!couponCode) {
      setAlertMessage('Please enter a coupon code.');
      setAlertType('error');
      return;
    }
    setLoading(true);
    setVerificationResult(null);
    setAlertMessage(''); // Reset the alert

    try {
      const data = couponCode.trim();
      const response = await api.registration.verifyCoupon({ couponCode: data });

      if (response && response.data?.isValid) {
        setAlertMessage('Coupon available and unused!');
        setAlertType('success');
        setVerificationResult(response.data);
      } else {
        setAlertMessage('No such coupon available.');
        setAlertType('error');
        setVerificationResult(response.data);
      }
    } catch (error) {
      setAlertMessage('No such coupon available.');
      setAlertType('error');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
    setAlertType('');
  };

  return (
    <>
      <div className="header-container"
        style={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          zIndex: 1
        }}>
        <Header />
      </div>

      <div className="banner-container" style={{ position: 'relative', textAlign: 'center' }}>
        <img
          src="https://www.excellencedriving.com/_next/image?url=https%3A%2F%2Fstorage.googleapis.com%2Fedc-storage%2Fwebsite%2FWebsite_Dextop_2_d2f7082b00%2FWebsite_Dextop_2_d2f7082b00.jpg&w=1920&q=75"
          alt="Banner"
          className="banner-image"
          style={{ width: "100%", height: "auto" }}
        />
        <div className="banner-text" style={{
          position: 'absolute',
          top: '80%',
          left: '30%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          fontSize: '4rem',
          fontWeight: 'bold',
          textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)'
        }}>
          Exclusive OFFER for IMSA
        </div>
      </div>

      <div className="container" style={{ marginTop: '2rem', display: 'flex', border: '1px solid rgba(221, 221, 221, 0.867)', borderRadius: '10px' }}>
        {/* Left Section (IMSA Logo and Input) */}
        <div className="left-section" style={{ flex: 1, marginRight: '2rem' }}>
          <div>
            <img
              src="https://storage.googleapis.com/edc-storage/website/IMSA_logo_page_0001_1_d5a6de01ea/IMSA_logo_page_0001_1_d5a6de01ea.jpg"
              alt="IMSA logo"
              className="img-fluid objectFit"
              style={{ width: '100%', height: 'auto', borderRadius: '5px' }}
            />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", margin: '1.2rem' }}>
              <input
                type="text"
                value={couponCode}
                onChange={handleCouponCodeChange}
                placeholder="Enter coupon code"
                style={{ padding: "0.7rem", width: '100%', margin: "2.3rem 0rem 1rem 0.1rem", border: '1px solid rgba(221, 221, 221, 0.867)' }}
              />

              {/* Custom Alert Box */}
              {alertMessage && (
                <div
                  style={{
                    margin: '1rem 1rem',
                    width: '250px',
                    height: '150px',
                    padding: '0.7rem',
                    position: 'relative',
                    borderRadius: '5px',
                    backgroundColor: alertType === 'success' ? '#60B046' : '#f8d7da',
                    color: alertType === 'success' ? 'white' : '#721c24',
                    border: `1px solid ${alertType === 'success' ? '#60B046' : '#f5c6cb'}`,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    
                  }}
                >

<span style={{ display: 'block', margin: '0 auto' }}>{alertMessage}</span>
                    {/* Close Button */}
                    <button
                      onClick={handleCloseAlert}
                      style={{
                        position: 'absolute',  
                        top: '10px',           
                        right: '10px',         
                        background: 'transparent',
                        border: 'none',
                        fontSize: '26px',
                        cursor: 'pointer',
                        color: alertType === 'success' ? '#60B046' : '#721c24',
                        zIndex: 1, 
                      }}
                    >
                      &times;
                    </button>
                </div> 
              )}

              <Button onClick={handleVerifyCoupon} className="custom-button">
                Verify
              </Button>
            </div>
          </div>
        </div>

        <div className="right-section" style={{ flex: 2, padding: '1rem' }}>
          <div>
            <h2 style={{ marginTop: '5px', fontSize: '0.80rem' }}>
              <strong>Exclusive offers - IMSA</strong>
            </h2>

            <p style={{ marginTop: '18px' }}>
              <a href="https://www.excellencedriving.com/images/imsa1.jpg" target="_blank">
                <img
                  src="https://www.excellencedriving.com/images/imsa1.jpg"
                  style={{ width: '100%', objectFit: 'contain', marginBottom: '1rem' }}
                  alt="Offer 1"
                />
              </a>
              <a href="https://www.excellencedriving.com/images/imsa2.jpg" target="_blank">
                <img
                  src="https://www.excellencedriving.com/images/imsa2.jpg"
                  style={{ width: '100%', objectFit: 'contain' }}
                  alt="Offer 2"
                />
              </a>
            </p>

            <h3>Terms & Conditions</h3>
            <ul style={{ lineHeight: '28px' }}>
              <li>Discount is applicable on LMV and Motorcycle categories.</li>
              <li>Discount can be availed by IMSA discount cardholders only.</li>
              <li>Discount is applicable only on Regular, Flexi, and Lumpsum packages.</li>
              <li>Discounts will be provided upon presenting proper identification.</li>
            </ul>

            <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }} className="pt-1 cust-care">
              <h2 className="text-primary font-weight-extrabolder">To know more:</h2>
              <a href="tel:600 515154" className="d-flex align-items-center text-dark font-weight-semibold font-base">
                <i
                  className="icon-in-call pr-2"
                  style={{ fontSize: '1.8125rem', color: '#008282', fontWeight: 'bold' }}
                ></i>
                <span style={{ direction: 'initial', fontSize: '1.3rem' }}>600 515154</span>
              </a>
            </div>

            <button
              style={{
                color: '#fff',
                backgroundColor: '#008282',
                borderColor: '#008282',
                marginTop: '1rem',
              }}
            >
              <a
                href="https://www.excellencedriving.com/en/request-callback"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                Enquire Now
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyCoupon;
