import React from 'react';
import { LogoutButtonProps } from './props';
import Button from '../../button';
import { useDispatch } from 'react-redux';

import { actions as userActions } from '../../../store/reducers/authUser';
import { actions as commonActions } from '../../../store/reducers/common';
import { useHistory } from 'react-router';
import { RouteKeys } from '../../../containers/route-keys';

const LogoutButton: React.SFC<LogoutButtonProps> = (
  props: LogoutButtonProps
) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const logout = () => {
    dispatch(userActions.logout());
    dispatch(commonActions.clearData());
    history.replace(RouteKeys.Login);
  };

  return (
    <Button className={props.className} type="button" btnlink onClick={logout}>
      <i className="icon-logout"></i>
      {' Logout'}
    </Button>
  );
};

export default LogoutButton;
