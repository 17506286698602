import { Dict } from '../models/interfaces';

import React, { useRef, useLayoutEffect } from 'react';
import { ScrollDirection } from '../models/scroll-directions';

const isBrowser = typeof window !== `undefined`;

const throttleTop = 10;

const throttleBottom = 10;

interface ScrollPosition {
  scrollTop: number;
  scrollLeft: number;
  scrollWidth: number;
  scrollHeight: number;
}

interface DomElementLayoutPosition {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

const getScrollPosition = ({ element }: { element?: any }): ScrollPosition => {
  if (!isBrowser) {
    return {
      scrollTop: 0,
      scrollLeft: 0,
      scrollWidth: 0,
      scrollHeight: 0,
    };
  }

  const target = element ? element.current : document.body;

  return {
    scrollTop: target.scrollTop,
    scrollLeft: target.scrollLeft,
    scrollWidth: target.scrollWidth,
    scrollHeight: target.scrollHeight,
  };
};
const getScrollLayoutPosition = ({
  element,
}: {
  element?: any;
}): DomElementLayoutPosition => {
  if (!isBrowser) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };
  }
  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return position;
};

export function useScrollPosition(
  effect: (direction: ScrollDirection) => void,
  deps: any[],
  element?: any,

  wait?: number
) {
  const position = useRef(getScrollPosition({}));

  let throttleTimeout: any = null;

  const triggerPageChange = (data: {
    prevPos: ScrollPosition;
    currPos: ScrollPosition;
    layoutPosition: DomElementLayoutPosition;
  }) => {
    const direction: 'up' | 'down' =
      data.currPos.scrollTop > data.prevPos.scrollTop ? 'down' : 'up';
    if (
      direction === 'down' &&
      data.currPos.scrollHeight -
        data.currPos.scrollTop -
        data.layoutPosition.height <=
        throttleBottom
    ) {
      effect(ScrollDirection.PageDown);
    } else if (direction === 'up' && data.currPos.scrollTop <= throttleTop) {
      effect(ScrollDirection.PageUp);
    }
  };

  const callBack = () => {
    const currPos = getScrollPosition({ element });
    const layoutPosition = getScrollLayoutPosition({ element });

    triggerPageChange({ currPos, prevPos: position.current, layoutPosition });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    const target = element.current;

    target.addEventListener('scroll', handleScroll);

    return () => target.removeEventListener('scroll', handleScroll);
  }, deps);
}
