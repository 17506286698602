/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

import './Header.scss';

import LogoImage from '../../assets/images/logo.svg';
import AppointmentImage from './calendar.svg';
import HeaderLanguageSelection from './LanguageSelection';
import StudentAccount from './StudentAccount';
import { Link } from 'react-router-dom';
import { store } from '../../store';
import { actions as userActions } from '../../store/reducers/authUser';
import { actions as commonActions } from '../../store/reducers/common';
import { RouteKeys } from '../../containers/route-keys';
interface CustomState {
  activeClass: string;
  mobDropdown: boolean;
}
const cmsDomain = 'https://www.excellencedriving.com/en';
const appStoreLinks = {
  android:
    'https://play.google.com/store/apps/details?id=com.excellencedriving.app&hl=en',
  ios: 'https://apps.apple.com/us/app/excellence-driving/id1494463689?ls=1',
};

class HeaderCanvas extends Component<{history?:any}, CustomState> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeClass: '',
      mobDropdown: false,
    };
  }
  onClick(activeClass: string) {
    this.setState({ activeClass });
  }
  logout = () => {
    const storeNew:any = store
    storeNew.dispatch(userActions.logout())
    storeNew.dispatch(commonActions.clearData())
    if(this.props.history){
      this.props.history.push(RouteKeys.Login)
    }
    
  }

  render() {
    const isAuthenticated = store.getState()?.authUser?.token ? true : false
    return (
      <header className="w-100 zIndex-9 position-relative">
        {/* position-absolute top-0 */}
        <div className="container">
          <div className="header-top d-none d-md-block border-left border-right">
            <div className="row">
              <div className="col">
                <div className="align-items-center d-flex download-app pl-3 ml-xl-5 pl-xl-5 d-sm-none d-lg-flex">
                  <p className="mr-3 mb-0">Download the app</p>
                  <a
                    className="mr-3 font-lg text-muted"
                    href={appStoreLinks.android}
                  >
                    <i className="icon-playstore"></i>
                  </a>
                  <a
                    className="mr-3 font-lg text-muted"
                    href={appStoreLinks.ios}
                  >
                    <i className="icon-apple"></i>
                  </a>
                </div>
              </div>
              <div className="col-auto">
                <div className="right-blk d-flex">
                  <div className="mr-80">
                    <a href={`${cmsDomain}/contact-us/`}>
                      Find Us
                      <i className="icon-location1 ml-2 font-md null"></i>
                    </a>
                  </div>
                  <div className="mr-80">
                    <a href="tel:+971600515154">
                      600515154<i className="icon-call ml-2 font-md null"></i>
                    </a>
                  </div>
                  <div className="d-flex">
                    <a
                      href={`https://www.excellencedriving.com/en/request-callback`}
                    >
                      Request A Call Back
                      <i className="icon-call ml-2 font-md font-lg mr-4"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-block bg-primary px-lg-5 px-md-3 py-2">
            <div className="row align-items-center gutter-8 py-md-1">
              <div className="col-auto d-md-none">
                <div className="menu">
                  <i
                    className="icon-menu text-black-50"
                    onClick={() => this.onClick('expand')}
                  ></i>
                </div>
              </div>
              <div className="col col-md text-center text-md-left">
                <div className="logo">
                  <a href={cmsDomain} title="Excellence Driving Centre">
                    <img
                      src={LogoImage}
                      alt="Logo"
                      className="img-fluid logo-header"
                    />
                  </a>
                </div>
              </div>
              <div className="col-auto d-md-none">
                <div className="search">
                  <i className="icon-search text-black-50"></i>
                </div>
              </div>
              <div className="col-md-auto">
                <div className={`navigation ${this.state.activeClass}`}>
                  <div className="menu-close d-md-none">
                    <i
                      className="icon-close"
                      onClick={() => this.onClick('')}
                    ></i>
                  </div>
                  <ul className="nav">
                    {
                      isAuthenticated ? null :
                      <li>
                        <Link to="/signup">
                          REGISTER<i className="undefined ml-2 font-md null"></i>
                        </Link>
                      </li> 
                    }
                    <li>
                      <div className="cursor-pointer">
                        EXPLORE
                        <span
                          className="drop"
                          onClick={() =>
                            this.setState({
                              mobDropdown: !this.state.mobDropdown,
                            })
                          }
                        >
                          {!this.state.mobDropdown ? (
                            <i className="icon-plus-2"></i>
                          ) : (
                            <i className="icon-minus-3"></i>
                          )}
                        </span>
                      </div>
                      <ul className="dropdown desktop">
                        <li>
                          <a href={`${cmsDomain}/car-driving`}>
                            Car License
                            <i className="undefined ml-2 font-md undefined"></i>
                          </a>
                        </li>
                        <li>
                          <a href={`${cmsDomain}/motorcycle`}>
                            Motorcycle License
                            <i className="undefined ml-2 font-md undefined"></i>
                          </a>
                        </li>
                        <li>
                          <a href={`${cmsDomain}/light-bus`}>
                            Light Bus License
                            <i className="undefined ml-2 font-md undefined"></i>
                          </a>
                        </li>
                        <li>
                          <a href={`${cmsDomain}/heavy-bus`}>
                            Heavy Bus License
                            <i className="undefined ml-2 font-md undefined"></i>
                          </a>
                        </li>
                        <li>
                          <a href={`${cmsDomain}/heavy-truck`}>
                            Heavy Truck License
                            <i className="undefined ml-2 font-md undefined"></i>
                          </a>
                        </li>
                        <li>
                          <a href={`${cmsDomain}/forklift`}>
                            Forklift License
                            <i className="undefined ml-2 font-md undefined"></i>
                          </a>
                        </li>
                      </ul>
                      {this.state.mobDropdown && (
                        <ul className="dropdown mobile">
                          <li>
                            <a href={`${cmsDomain}/car-driving`}>
                              Car License
                              <i className="undefined ml-2 font-md undefined"></i>
                            </a>
                          </li>
                          <li>
                            <a href={`${cmsDomain}/motorcycle`}>
                              Motorcycle License
                              <i className="undefined ml-2 font-md undefined"></i>
                            </a>
                          </li>
                          <li>
                            <a href={`${cmsDomain}/light-bus`}>
                              Light Bus License
                              <i className="undefined ml-2 font-md undefined"></i>
                            </a>
                          </li>
                          <li>
                            <a href={`${cmsDomain}/heavy-bus`}>
                              Heavy Bus License
                              <i className="undefined ml-2 font-md undefined"></i>
                            </a>
                          </li>
                          <li>
                            <a href={`${cmsDomain}/heavy-truck`}>
                              Heavy Truck License
                              <i className="undefined ml-2 font-md undefined"></i>
                            </a>
                          </li>
                          <li>
                            <a href={`${cmsDomain}/forklift`}>
                              Forklift License
                              <i className="undefined ml-2 font-md undefined"></i>
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <a href={`${cmsDomain}/experience`}>
                        EXPERIENCE
                        <i className="undefined ml-2 font-md font-weight-bold"></i>
                      </a>
                    </li>
                    {
                      isAuthenticated ? 
                      <li>
                        <div className="cursor-pointer" style={{cursor:"pointer"}}>
                        <span onClick={() => this.logout()}>
                          LOGOUT<i className="undefined ml-2 font-md null"></i>
                        </span>
                        </div>
                        
                    </li> :
                    <li>
                      <Link to="/auth/login">
                        LOGIN<i className="undefined ml-2 font-md null"></i>
                      </Link>
                    </li>
                    }
                    
                  </ul>
                  <div className="inner-nav d-md-none">
                    <a
                      className="align-items-center d-flex font-lg font-weight-semibold my-3 pt-2"
                      href={`${cmsDomain}/contact-us/`}
                    >
                      <i className="icon-location1 font-xxl mr-2 before-gray"></i>
                      Find Us
                    </a>
                    <a
                      className="align-items-center d-flex font-lg font-weight-semibold my-3 pt-2"
                      href="tel:+971600515154"
                    >
                      <i className="icon-call font-xxl mr-2 before-gray"></i>
                      600515154
                    </a>
                    <Link
                      to={`/book-an-appointment`}
                      className="align-items-center d-flex font-lg font-weight-semibold my-3 pt-2"
                    >
                      <i className="icon-date font-xxl mr-2 before-gray"></i>
                      Book an Apptointment
                    </Link>
                    <div className="switchs"></div>
                    <div className="align-items-center d-flex d-lg-flex download-app font-lg font-weight-semibold ml-xl-5 mob-app mt-3 pl-2 pl-xl-5 pt-3">
                      <p className="mr-3 mb-0">Download the app</p>
                      <a href={appStoreLinks.android} className="mr-3 font-lg">
                        <i className="icon-playstore before-gray"></i>
                      </a>
                      <a href={appStoreLinks.ios} className="font-lg">
                        <i className="icon-apple before-gray"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      // <header className="header">
      //   <div className="d-lg-none d-flex align-items-center col-12 mob-header h-100 py-3">
      //     <i
      //       className="icon-menu menu-toggle text-white"
      //       onClick={() => this.onClick('active')}
      //     ></i>
      //     <div
      //       id="logo"
      //       className="d-block logo ml-3 ml-lg-0 w-100 text-center"
      //     >
      //       <a href={`${domain}/`} title="Excellence Driving Centre">
      //         <img
      //           src={LogoImage}
      //           alt="Logo"
      //           className="img-fluid logo-header-mob"
      //         />
      //       </a>
      //     </div>
      //   </div>
      //   <div className={`nav-overlay ${this.state.activeClass}`}></div>
      //   <div className={`nav-outer ${this.state.activeClass}`}>
      //     <a
      //       href="#"
      //       className="menu-close position-fixed rounded-circle text-center d-lg-none"
      //     >
      //       <i className="icon-close" onClick={() => this.onClick('')}></i>
      //     </a>
      //     <nav className="d-flex d-lg-block flex-column-reverse justify-content-end ">
      //       <div className="header-top py-2 ">
      //         <div className="container my-1">
      //           <div className="row flex-lg-row flex-column-reverse">
      //             <div className="col-lg-4 col-12 d-flex align-items-center mt-lg-0 mt-5">
      //               <span className="mr-4">Download Excellence App</span>
      //               <ul className="list-unstyled p-0 m-0 font-lg d-flex">
      //                 <li className="d-inline-flex align-items-center">
      //                   <a
      //                     href={appStoreLinks.ios}
      //                     className="d-inline-flex align-items-center"
      //                   >
      //                     <i className="icon-app-store"></i>
      //                   </a>
      //                 </li>
      //                 <li className="d-inline-flex align-items-center">
      //                   <span className="mx-3 separator"></span>
      //                 </li>
      //                 <li className="d-inline-flex align-items-center">
      //                   <a
      //                     href={appStoreLinks.android}
      //                     className="d-inline-flex align-items-center"
      //                   >
      //                     <i className="icon-play-store"></i>
      //                   </a>
      //                 </li>
      //               </ul>
      //             </div>
      //             <div className="col-lg-8 col-12">
      //               <ul className="list-unstyled p-0 m-0 d-flex align-items-lg-center justify-content-end flex-lg-row flex-column">
      //                 <li>
      //                   <a
      //                     href={`${domain}/contact-us/`}
      //                     className="d-inline-flex align-items-center flex-lg-row flex-row-reverse"
      //                   >
      //                     Find Us{' '}
      //                     <i className="icon-pointer ml-lg-2 mr-lg-0 mr-4"></i>
      //                   </a>
      //                 </li>
      //                 <li className="pl-lg-4 ml-lg-2">
      //                   <a
      //                     href="tel:600515154"
      //                     className="d-inline-flex align-items-center flex-lg-row flex-row-reverse"
      //                   >
      //                     600515154{' '}
      //                     <i className="icon-landline ml-lg-3 mr-lg-0 mr-4"></i>
      //                   </a>
      //                 </li>
      //                 <li className="pl-lg-4 ml-lg-2">
      //                   <a
      //                     href={`${process.env.REACT_APP_URL}/book-an-appointment`}
      //                     className="d-inline-flex align-items-center flex-lg-row flex-row-reverse"
      //                   >
      //                     Book An Appointment{' '}
      //                     <img
      //                       src={AppointmentImage}
      //                       alt="Logo"
      //                       className="ml-lg-2 mr-lg-0 mr-4"
      //                     />
      //                     {/* <i className="icon-calendar ml-lg-2 mr-lg-0 mr-4"></i> */}
      //                   </a>
      //                 </li>
      //                 {/* <li className="pl-lg-4 ml-lg-2">
      //                   <a
      //                     href="mailto: info@excellencedriving.com"
      //                     className="d-inline-flex align-items-center flex-lg-row flex-row-reverse"
      //                   >
      //                     info@excellencedriving.com{' '}
      //                     <i className="icon-envelop-outline ml-lg-3 mr-lg-0 mr-4"></i>
      //                   </a>
      //                 </li> */}
      //                 <li className="pl-lg-4 ml-lg-2 position-relative d-none">
      //                   <HeaderLanguageSelection currentLanguage={'en'} />
      //                 </li>
      //               </ul>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <div className="btm-bar header-btm">
      //         <div className="container clearfix">
      //           <div className="row align-items-lg-center align-items-start gutter-10 ">
      //             <div className="col-xl-3 col-3 menu-logo mr-auto d-lg-inline-block d-none ">
      //               <div className="xs-logo d-md-inline-block d-none">
      //                 <div className="icon-close2 close-menu"></div>
      //                 <a href={`${domain}/`} title="Excellence Driving Centre">
      //                   <img
      //                     src={LogoImage}
      //                     alt="Logo"
      //                     className="img-fluid logo-header"
      //                   />
      //                 </a>
      //               </div>
      //             </div>
      //             <div className="col d-flex font-weight-normal justify-content-end">
      //               <ul className="list-unstyled m-0 mainmenu mb-0 row">
      //                 <li className="d-lg-none current ">
      //                   {' '}
      //                   <a href={`${domain}/`} className="active">
      //                     Home
      //                   </a>
      //                 </li>
      //                 <li className="shade1  ">
      //                   {' '}
      //                   <a href={`${domain}/car-driving/`}>Car</a>
      //                 </li>
      //                 <li className="shade2  ">
      //                   {' '}
      //                   <a href={`${domain}/motorcycle/`}>Motorcycle</a>
      //                 </li>
      //                 <li className="shade3  ">
      //                   {' '}
      //                   <a href={`${domain}/light-bus/`}>Light Bus</a>
      //                 </li>
      //                 <li className="shade4  ">
      //                   {' '}
      //                   <a href={`${domain}/heavy-bus/`}>Heavy Bus</a>
      //                 </li>
      //                 <li className="shade5  ">
      //                   {' '}
      //                   <a href={`${domain}/heavy-truck`}>Heavy Truck</a>
      //                 </li>
      //                 <li className="shade7  ">
      //                   {' '}
      //                   <a href={`${domain}/forklift/`}>Forklift</a>
      //                 </li>
      //               </ul>
      //             </div>
      //             <div className="col-lg-auto col-12">
      //               <StudentAccount />
      //             </div>
      //             <div className="border-bottom d-lg-none login-register-menu order-2 order-lg-0 pl-lg-2 pr-lg-3 d-none">
      //               <a
      //                 href="#"
      //                 className="align-items-center change-language cursor-pointer row m-0"
      //               >
      //                 <span className="line-height-normal p-0 font-xs col-6">
      //                   Switch to
      //                 </span>
      //                 <span className="col-6 text-right p-0">
      //                   <img
      //                     className="img-fluid mr-2"
      //                     src="https://www.excellencedriving.com/public/uploads/languages/ar.png"
      //                     alt="عربى"
      //                   />{' '}
      //                   عربى
      //                 </span>
      //               </a>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </nav>
      //   </div>
      // </header>
    );
  }
}

export default HeaderCanvas;
