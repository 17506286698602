import React from 'react';
// import PropTypes, {InferProps} from 'prop-types';
import { Link } from 'react-router-dom';

import './ClickLink.scss';

interface CustomProps {
  size?: 'small' | 'medium' | 'large';
  children: any;
  link?: string;
  onClick?: () => void;
  underline?: boolean;
  className?: string;
  style?: any;
  align?: 'left' | 'center' | 'right';
  primary?: boolean;
}

const ClickLink: React.SFC<CustomProps> = (props: CustomProps) => {
  // either link or onClick is allowed
  if (props.link && props.onClick) {
    throw new Error('Both link & onClick is not acceptable. Provide either.');
  }
  let classes = ['ClickLink', `ClickLink--${props.size}`].join(' ');
  if (props.underline) {
    classes = `${classes} ClickLink--underline`;
  }
  if (props.primary) {
    classes = `${classes} ClickLink--primary`;
  }
  classes = `${classes} ${props.className}`;

  const { style = {} } = props;
  if (props.link) {
    return (
      <Link className={classes.trim()} to={props.link} style={style}>
        {props.children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={classes}
      style={style}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.children}
    </button>
  );
};

// ClickLink.propTypes = {
//   size: PropTypes.oneOf(['small', 'medium', 'large']),
//   children: PropTypes.string.isRequired,
//   link: PropTypes.string,
//   onClick: PropTypes.func,
//   underline: PropTypes.bool,
//   className: PropTypes.string,
//   // pos: PropTypes.number,
//   // align: PropTypes.oneOf(['left', 'right']),
// };

ClickLink.defaultProps = {
  size: 'small',
  underline: false,
  className: '',
};
export default ClickLink;
