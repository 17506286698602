import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import jwt, { JwtPayload } from 'jsonwebtoken';
const TokenVerifier = () => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const verifyToken = async (value:any) => {
    if (value) {
        try {
          const secret = 'RTABus'
          const decoded = jwt.verify(value,secret) as JwtPayload
         
          if  (decoded && typeof decoded.exp === 'number' && Date.now() < decoded.exp * 1000) {
          
            history.push(`/signup?token=${value}`);
          }
          else {
          
            history.push('/page-not-found');
           
          }
          
        } catch (err) {
          
          alert('Invalid or expired token. Please try again.');
          history.push('/page-not-found');
        }
      } else {
        alert('Access denied. Please use a valid registration link.');
        history.push('/page-not-found'); 
      }
    }
    verifyToken(token);
    }, [location.search, history]);
  
    return null; 
  };
export default TokenVerifier;
