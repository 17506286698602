import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'user/v1';

export default class PaymentService extends ApiService {
  public async verifyPayment(
    referenceCode: string,
    retryPayment: boolean,
    referenceNumber?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/verify-payment`;
    return http.post(
      url,
      { paymentReference: referenceCode, retryPayment, referenceNumber },
      this.token
    );
  }
  public async getAdditionalFees() {
    const url = `${this.apiDomain}/${serviceEndpoint}/additional-fees`;
    return http.get(url, this.token);
  }
  public async getAdditionalFeeDetails(type: string, userId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/additional-fees/${userId}/${type}`;
    return http.get(url, this.token);
  }
  public async initiatePayment(data: any) {
    let url = `${this.apiDomain}/${serviceEndpoint}/additional-fees`;
    if (data?.feeDetails?.isAbsenteeismFee) {
      url = `${this.apiDomain}/${serviceEndpoint}/additional-fees/initiate-absenteeism-payment`;
    }
    return http.post(url, data, this.token);
  }
  public async collectAdditionalFees(data: any, userId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/additional-fees/${userId}`;
    return http.post(url, data, this.token);
  }

  public async verifyAdditionalFees(data: any, userId: string) {
    let url = `${this.apiDomain}/${serviceEndpoint}/additional-fees/${userId}/verify-payment`;
    if (data?.isAbsenteeismFee) {
      url = `${this.apiDomain}/${serviceEndpoint}/additional-fees/absenteeism-addons/${userId}/verify-payment`;
    }
    return http.post(url, data, this.token);
  }

  public async verifyPaymentForLink(
    user:string,
    referenceCode: string,
    retryPayment: boolean,
    referenceNumber?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/verify-payment-for-payment-link`;
    return http.post(
      url,
      { paymentReference: referenceCode, retryPayment, referenceNumber, user },
    );
  }
}
