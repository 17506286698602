import React from 'react';

import Logo from '../../components/logo/Logo';

import './StepHeader.scss';

const StepHeader = () => {
  return (
    <div className="StepHeader">
      <Logo colored />
    </div>
  );
};

export default StepHeader;
