import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { actions as authActions } from '../../store/reducers/authUser';
import {
  actions as registrationActions,
  paymentPlans,
  paymentModes,
} from '../../store/reducers/registration';
/* eslint jsx-a11y/anchor-is-valid: "off" */

import Button from '../../components/button';
import SlideBox from '../../components/slide-box';
import ApplyPromotion from '../../components/on-boarding/promo-code';

import PaymentPlan from '../../components/on-boarding/payment-plan';
import PaymentSummary from '../../components/on-boarding/payment-summary';
import User from '../../models/user';
import PaymentOption from '../../components/on-boarding/payment-option';

import './PaymentMethod.scss';
import { ContainerLoader } from '../../components/loader';
import { State } from '../../store/interfaces';
import { TextInput } from '../../components/form-group';
import api from '../../api';
import ApplyReferenceNumber from '../../components/on-boarding/reference-number';

interface CustomProps {
  isLoading: boolean;
  history:any;
  location: any;
  hasPromotion: boolean;
  addSummary?: (data: any) => void;
  user?: User;
  gotoNextBlock?: () => any;
  selectFullPayment?: () => any;
  selectStagePayment?: () => any;
  course: any;
  fullPaymentSelected: boolean;
  totalAmount?: number;
  totalForMilestones?: number;
  totalRtaForMilestones?: number;
  totalEdsForMilestones?: number;
  promotion?: any;
  summary: any;
  paymentMethod: paymentModes;
  selectPayAtEDC: () => void;
  selectPayOnline: () => void;
  courseType: string;
  milestones: any[];
  payingAmount: number;
  removePromoCode: (courseId: string) => void;
  completePayment: (
    milestones: any[],
    payingAmount: number,
    courseType: string,
    promotion: any,
    retryPayment: boolean,
    promoCode?: string,
    referenceNumber?: string
  ) => void;
  OnlinePaymentStatus?: string;
  showPromoCodeBox?: boolean;
  promotions?: any[];
  promoCode?: string;
  classes?: string;
  onApply: (value: any) => void;
  logout: () => void;
  applyReferenceNumber: (value: any) => void;
  referenceNumber: string;
  isRTAToken:boolean;
  removePromotion: () => void;
}

interface CustomState {
  isReferenceNumberValidation: boolean;
  referenceNumber: string;
  isValidating: boolean;
  isReferenceNumberVerify: boolean;
}
class PaymentPlans extends Component<CustomProps, CustomState> {

  static defaultProps: Partial<CustomProps> = {
    summary: {},
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isReferenceNumberValidation: true,
      referenceNumber: this.props.referenceNumber,
      isValidating: false,
      isReferenceNumberVerify: false,
    
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onApply = this.onApply.bind(this);
    this.validateReferenceNumber = this.validateReferenceNumber.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount() {

    const { location, history } = this.props || { location: {}, history: {} };
   
  

    if (!this.props.promotion?.isReferenceNumberMandatory) {
      this.setState({ isReferenceNumberValidation: false });
    }
  }

  componentDidUpdate(prevProps: CustomProps) {
    
    if (
      prevProps.promotion !== this.props.promotion &&
      this.props.promotion?.isReferenceNumberMandatory
    ) {
      this.setState({
        isReferenceNumberValidation: true,
        isReferenceNumberVerify: false,
      });
    }
    if (
      prevProps.promotion !== this.props.promotion &&
      !this.props.promotion?.isReferenceNumberMandatory
    ) {
      this.setState({ isReferenceNumberValidation: false, isReferenceNumberVerify: false });
    }
  }

  async onSubmit() {
    try {
      if (this.props.addSummary) {
        this.props.addSummary({
          key: 'paymentplan',
          label: 'Your choose payment plan as',
          value: this.props.fullPaymentSelected
            ? 'Full Payment'
            : 'Stage payment',
        });
        this.props.addSummary({
          key: 'paymableamount',
          label: 'and amount payable is',
          value: `AED ${(this.props.totalAmount || 0).toFixed(2)}`,
        });
      }
      await this.props.completePayment(
        this.props.milestones,
        this.props.payingAmount,
        this.props.courseType,
        this.props.promotion,
        this.props.OnlinePaymentStatus === 'failed',
        this.props.promoCode,
        this.state.referenceNumber
      );
      if (this.props.gotoNextBlock) this.props.gotoNextBlock();
    } catch (err) {
      if (err.message === 'User has already done payment') {
        toastr.error(
          'Error',
          'User has already done payment. Please login again'
        );
        this.props.logout();
      } else {
        toastr.error('Error', err.response.data.message);
      }
    }
  }

  onChange() {
    // this.props.removePromoCode(this.props.course.course._id);
  }

  onApply(value:any) 
  {

    this.props.onApply(value);
  }

  onRemove() {
    this.setState({
      isReferenceNumberVerify: false,
    });
    this.props.removePromotion()
  }

  async validateReferenceNumber() {
    
    this.setState({ isValidating: true });
    if (this.state.referenceNumber?.trim()) {
      try {
        const data = {
          promoCodeId: this.props.promotion?._id,
          referenceNumber: this.state.referenceNumber,
        };
        const response = await api.registration.validateReferenceNumber(data);
        if (response && response.data && response.data.isValid) {
          this.setState({
            isReferenceNumberValidation: false,
            isReferenceNumberVerify: true,
          });
          this.props.applyReferenceNumber(this.state.referenceNumber);
          toastr.success('Verification Success', 'Continue with payment');
        } else {
          toastr.error(
            'Verification Error',
            'Please enter a valid reference number'
          );
        }
      } catch (error) {
        toastr.error(
          'Verification Error',
          'Reference Number verification failed.'
        );
      }
    } else {
      toastr.error('Error', 'Please enter your Reference Number.');
    }
    this.setState({ isValidating: false });
  }
  renderPromotionInput() {
    if (this.props.showPromoCodeBox) {
      return (
        <SlideBox
          showPromoCodeBox={this.props.showPromoCodeBox}
          promotions={this.props.promotions}
          promoCode={this.props.promoCode}
          onApply={this.onApply}
          classes={this.props.classes}
        />
      );
    }

    return (
      <>

     
      
        <ApplyPromotion
          promotion={this.props.promotion}
          amount={this.props.summary.totalDiscount}
          course={this.props.course.course.code}
          showRemoveButton={true}
          showPromoCodeBox={this.props.showPromoCodeBox}
          onChange={this.onChange}
        />
    

        {this.props.promotion && (
          <div className="text-right">
            {
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            }
            <a
              role="button"
              tabIndex={0}
              className="text-primary py-2 d-inline-block font-small px-3"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => this.onRemove()}
            >
              Continue without promotion
            </a>
          </div>
        )}
        {this.props.promotion?.isReferenceNumberMandatory &&
          !this.state.isReferenceNumberVerify && (
            <div className="PromocodeOuter mt-4">
              <div className="d-inline-block">
                <TextInput
                  placeholder="Enter Reference Number"
                  label="Reference Number"
                  onChange={(val: string) => {
                    this.setState({ referenceNumber: val });
                  }}
                  disabled={this.state.isValidating}
                  default={this.state.referenceNumber}
                ></TextInput>
              </div>
              <div className="d-inline-block ml-2">
                <Button
                  onClick={this.validateReferenceNumber}
                  expand
                  disabled={this.state.isValidating}
                >
                  Verify
                </Button>
              </div>
            </div>
          )}
      </>
    );
  }

  renderPayOnlineMethod() {
    

    return (
      <PaymentOption
        onSelect={() => this.props.selectPayOnline()}
        id="pay-method-online"
        // disabled
        label="Pay Online"
        icon="icon-payonline"
        selected={this.props.paymentMethod === paymentModes.online}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPayAtEDCMethod() {
    return (
      <PaymentOption
        onSelect={() => this.props.selectPayAtEDC()}
        id="pay-method-at-edc"
        label="Pay At EDC"
        icon="icon-pay-at-edc"
        selected={this.props.paymentMethod === paymentModes.edsCenter}
      />
    );
  }

  renderPaymentMethods() {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    return (
      <div className="PaymentMethod__left mb-4">
        <p className="font-base text-body font-weight-medium">
          Choose payment method
        </p>
        <div className="row">
          {this.renderPayOnlineMethod()}
          {token ? null : this.renderPayAtEDCMethod()}
        </div>
        {/* <div className="card-detail mt-4">
            {this.renderCardInput()}
          </div> */}
      </div>
    );
  }

  renderCompletePaymentButton() {
    const buttonText = this.props.isLoading
      ? 'Please wait'
      : 'Complete Payment';
    return (
      <div className="d-inline-block w-100">
        <div className="PaymentMethod__btn">
          <Button
            type="button"
            expand
            disabled={
              this.props.isLoading || this.state.isReferenceNumberValidation
            }
            onClick={() =>
              this.props.completePayment(
                this.props.milestones,
                this.props.payingAmount,
                this.props.courseType,
                this.props.promotion,
                this.props.OnlinePaymentStatus === 'failed',
                this.props.promoCode,
                this.state.referenceNumber
              )
            }
          >
            {buttonText}
          </Button>
        </div>
      </div>
    );
  }

  renderContinueToPaymentButton() {
    return (
      <div className="d-inline-block w-100">
        <Button
          onClick={this.onSubmit}
          disabled={
            this.props.payingAmount <= 0 ||
            this.state.isReferenceNumberValidation
          }
          expand
        >
          Proceed to Pay
        </Button>
      </div>
    );
  }

  render() {
    if (this.props.isLoading || !this.props.course) {
      return (
        <ContainerLoader
          height={500}
          text={'Loading course structure..'}
        ></ContainerLoader>
      );
    }
    return (
      <div className="ChoosePaymentPlan">
        <h5 className="font-xxs text-body text-uppercase font-weight-semibold mb-3 letter-spacing">
          Starter plan is right for you.
        </h5>
        <div className="PaymentPlan">
          <PaymentPlan
            key="full-payment"
            id="full-payment"
            name="Full payment"
            description={
              this.props.course.amount.totalDiscount > 0
                ? `You save AED ${this.props.course.amount.totalDiscount?.toFixed(2)}`
                : ''
            }
            totalAmount={
              this.props.course.amount.totalAmount -
              this.props.course.amount.totalDiscount
            }
            totalRtaAmount={this.props.course.amount.rta.totalAmount}
            totalEdsAmount={this.props.course.amount.eds.totalAmount}
            allowPartialPayment={false}
            selected={this.props.fullPaymentSelected}
            onSelect={this.props.selectFullPayment}
            primary
          />
          <PaymentPlan
            id="stage-payment"
            name="Choose what you want to pay"
            key="stage-payment"
            description="You can pay for multiple stages"
            totalAmount={this.props.totalForMilestones}
            totalRtaAmount={this.props.totalRtaForMilestones}
            totalEdsAmount={this.props.totalEdsForMilestones}
            allowPartialPayment
            selected={!this.props.fullPaymentSelected}
            onSelect={this.props.selectStagePayment}
          />
        </div>
        {/* {this.props.hasPromotion && ( */}
        <div className="PromocodeOuter mb-4">
          <p className="font-base py-3 mb-0 text-body font-weight-medium">
            Promocodes
          </p>
          {this.renderPromotionInput()}
        </div>
        {this.state.isReferenceNumberVerify && (
          <div className="PromocodeOuter mb-4">
            <p className="Input__label">Reference Number</p>
            <ApplyReferenceNumber
              showRemoveButton
              referenceNumber={this.state.referenceNumber}
              onChange={() => {
                if (this.props.promotion?.isReferenceNumberMandatory) {
                  this.setState({ isReferenceNumberValidation: true });
                }
                this.setState({ isReferenceNumberVerify: false });
              }}
            />
          </div>
        )}
        {/* )} */}
        <PaymentSummary summary={this.props.summary} />
        {this.renderPaymentMethods()}
        {this.props.paymentMethod === paymentModes.edsCenter &&
          this.renderCompletePaymentButton()}
        {this.props.paymentMethod === paymentModes.online &&
          this.renderContinueToPaymentButton()}
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  isLoading: state.registration.isLoading,
  user: state.registration.user,
  course: state.registration.course,
  hasPromotion: state.registration.promotion != null,
  promotion: state.registration.promotion,
  summary: state.registration.summary,
  totalAmount:
    state.registration.summary.totalAmount +
    state.registration.summary.totalTax -
    state.registration.summary.totalDiscount,
  fullPaymentSelected:
    state.registration.paymentPlan === paymentPlans.fullPayment,
  selectedMilestones: state.registration.stagePayment.milestones,
  totalForMilestones:
    state.registration.stagePayment.summary.totalAmount -
    state.registration.stagePayment.summary.totalDiscount,
  totalRtaForMilestones:
    state.registration.stagePayment.summary.eds.totalAmount,
  totalEdsForMilestones:
    state.registration.stagePayment.summary.rta.totalAmount,
  courseType: state.registration.courseType as string,
  paymentMethod: state.registration.modeOfPayment,
  milestones:
    state.registration.course &&
    state.registration.paymentPlan === paymentPlans.fullPayment
      ? state.registration.course.milestones.map((ml: any) => ({ _id: ml._id }))
      : state.registration.stagePayment.milestones.map((ml: any) => ({
          _id: ml._id,
        })),
  payingAmount:
    state.registration.summary.totalAmount +
    state.registration.summary.totalTax -
    state.registration.summary.totalDiscount,
  OnlinePaymentStatus: state.registration.onlinePaymentStatus,
  showPromoCodeBox: state.registration.showPromoCodeBox,
  classes: state.registration.showPromoCodeBox ? 'slideBox active' : 'slideBox',
  promotions: state.registration.promotions,
  promoCode: state.registration.promoCode,
  referenceNumber: state.registration.referenceNumber
    ? state.registration.referenceNumber
    : '',
});

const mapDispatchToProps = (dispatch: any) => ({
  selectStagePayment: () =>
    dispatch(registrationActions.changePaymentPlan(paymentPlans.stagePayment)),
  selectFullPayment: () =>
    dispatch(registrationActions.changePaymentPlan(paymentPlans.fullPayment)),
  onMilestoneSelection: (milestone: any, selected: boolean) =>
    dispatch(registrationActions.selectMilestone(milestone, selected)),
  selectPayAtEDC: () =>
    dispatch(registrationActions.changePaymentMethod(paymentModes.edsCenter)),
  selectPayOnline: () =>
    dispatch(registrationActions.changePaymentMethod(paymentModes.online)),
  completePayment: (
    milestones: any[],
    amount: number,
    courseType: string,
    promotion: any,
    retryPayment: boolean,
    promoCode?: string,
    referenceNumber?: string
  ) =>
    dispatch(
      registrationActions.initiatePayment(
        milestones,
        amount,
        courseType,
        promotion,
        retryPayment,
        promoCode,
        referenceNumber
      )
    ),
  removePromoCode: (courseId: string) =>
    dispatch(registrationActions.clearPromoCode(courseId)),
  onApply: (promoCode: string) =>
    dispatch(registrationActions.applyPromoCode(promoCode)),
  logout: () => dispatch(authActions.logout()),
  applyReferenceNumber: (referenceNumber: string) =>
    dispatch(registrationActions.applyReferenceNumber(referenceNumber)),
  removePromotion: () => dispatch(registrationActions.removePromotion()),
});

// PaymentPlans.propTypes = {
//   isLoading: PropTypes.bool,
//   hasPromotion: PropTypes.bool,
//   user: PropTypes.shape({}),
//   course: PropTypes.shape({
//     amount: PropTypes.shape({
//       totalAmount: PropTypes.number,
//       totalDiscount: PropTypes.number,
//       rta: PropTypes.shape({ totalAmount: PropTypes.number }),
//       eds: PropTypes.shape({ totalAmount: PropTypes.number }),
//       promotions: PropTypes.arrayOf(PropTypes.shape({})),
//     }),
//   }),
//   addSummary: PropTypes.func.isRequired,
//   gotoNextBlock: PropTypes.func.isRequired,
//   fullPaymentSelected: PropTypes.bool.isRequired,
//   totalAmount: PropTypes.number.isRequired,
//   totalForMilestones: PropTypes.number.isRequired,
//   totalRtaForMilestones: PropTypes.number.isRequired,
//   totalEdsForMilestones: PropTypes.number.isRequired,
//   onSubmit: PropTypes.func.isRequired,
//   selectFullPayment: PropTypes.func.isRequired,
//   selectStagePayment: PropTypes.func.isRequired,
//   summary: PropTypes.shape({
//     totalAmount: PropTypes.number,
//     totalTax: PropTypes.number,
//     totalDiscount: PropTypes.number,
//   }),
//   promotion: PropTypes.shape({}),
// };

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlans);
