import React from 'react';
// import PropTypes from 'prop-types';

import './CouponCard.scss';
import PromoCode from '../promocode';

interface CustomProps {
  onApply: (value: string) => void;
  promoCode?: string;
  promotions?: any[];
}

interface CustomState {
  value: string;
}

class CouponCard extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {};

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: '',
    };
    this.renderCouponCard = this.renderCouponCard.bind(this);
    this.renderCouponCards = this.renderCouponCards.bind(this);
    this.onClick = this.onClick.bind(this);
    // this.handleKeyUp = this.handleKeyUp.bind(this);
  }
  renderCouponCards(promotions: any[]) {
    return promotions.map((promo) => this.renderCouponCard(promo));
  }

  onClick(promoCode: string) {
    this.props.onApply(promoCode);
  }
  renderCouponCard(promotion: any) {
    return (
      <div className="CouponCard__item">
        <div className="CouponCard-inner">
          <PromoCode promoCode={promotion.publicPromoCode}></PromoCode>
          <h6 className="CouponCard__title">{promotion.description}</h6>
          <p className="CouponCard__cnt">{promotion.name}</p>
          <div className="CouponCard__viewbtn">
            <button type="button" className="Button Button--sm Button__link">
              View Details
            </button>
          </div>
          <div className="CouponCard__btn">
            <button
              type="button"
              className="Button Button--sm Button__outline-primary"
              onClick={() => this.onClick(promotion.publicPromoCode)}
            >
              Apply Coupon
            </button>
          </div>
        </div>
      </div>
    );
  }
  render() {
    if (this.props.promotions?.length) {
      return (
        <div className="CouponCard">
          {this.renderCouponCards(this.props.promotions)}
        </div>
      );
    }
  }
}

// Search.propTypes = {
//   direction: PropTypes.oneOf(['left', 'right']),
//   onSearch: PropTypes.func.isRequired,
// };

// Search.defaultProps = {
//   direction: 'left',
// };

export default CouponCard;
