import React, { useEffect } from 'react';
import { createPaymentResponseMessage } from '../../utils/payment';
import { ContainerLoader } from '../../components/loader';
import { useSelector, useDispatch, connect } from 'react-redux';
import {
  OnlinePaymentStatus,
  paymentModes,
  actions as paymentActions,
} from '../../store/reducers/payment';
import { useHistory } from 'react-router';
import { RouteKeys } from '../route-keys';

interface CustomProps {
  location: any;
}

const PaymentCallBackPage: React.SFC<CustomProps> = (props) => {
  const dispatch = useDispatch()
  const history  = useHistory()
  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    if(urlParams.get('pay-by-link')){
      const ref:any  = urlParams.get('ref')
      const data = {ref}
      dispatch(
        paymentActions.verifyPayment(data, false, onPaymentSuccess, true)
      );
    }
    else{
      window.parent.postMessage(
        createPaymentResponseMessage(props.location),
        '*'
      );
    }  
  },[]);

  const onPaymentSuccess = () => {
    history.replace(RouteKeys.PaymentThankYou);
  }

  return <ContainerLoader height={800}></ContainerLoader>;
};

export default PaymentCallBackPage;
