import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import StepForm from './StepForm';
import StepVisual from './StepVisual';
import StepHeader from './StepHeader';

import './Step.scss';

class Step extends Component {
  render() {
    return (
      <>
        <div className="Verification py-lg-5 my-4 text-lg-left text-center">
          <div className="container">
            <div className="mb-5 pb-lg-5">
              <StepHeader />
            </div>
            <div className="row">
              <div className="col-lg-7 col-12 mb-md-0 mb-4">
                <StepVisual />
              </div>
              <div className="col-lg-5 col-12">
                <StepForm />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } // end of render
}

// Step.propTypes = {};

// Step.defaultProps = {};

export default Step;
