// core
import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
// action creators
import { actions as authUserActions } from '../../store/reducers/authUser';

// components
import { OTPInput } from '../../components/form-group';

// helpers
import { validateFields, serializeFields, validators } from '../helpers';
import Button from '../../components/button';

import './StepForm.scss';
import { Dict } from '../../models/interfaces';
import { State } from '../../store/interfaces';
import { useGTagConversionTracker } from '../../hooks/gtag';
import { GtagSendTo } from '../../utils/gtag';

const StepForm: React.SFC = () => {
  useGTagConversionTracker({
    allow_custom_scripts: true,
    send_to: GtagSendTo.SignupOTP,
  });

  const { isLoading, verify } = useSelector((state: State) => state.authUser);

  const [fields, setFields] = useState<Dict>({
    otp: {
      value: '',
      label: 'OTP',
      error: '',
      validators: [validators.minLength(6, 'OTP should have 6 letters')],
    },
  });

  const [token, setToken] = useState <string | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
 
  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const value = queryParams.get('token');
    if (value) {
      setToken(value);
    } 
  }, [history.location.search, history]);


  const onSubmit = (credentials: any) =>
    dispatch(
      authUserActions.loginWithOTP(credentials, () =>
        dispatch(authUserActions.fetchProfile())
      )
    );
  const sendOTPAction = (id: string) => dispatch(authUserActions.sendOTP(id));

  const getInput = (inputField: string, value: any) => {
    setFields({
      ...fields,
      [inputField]: {
        ...fields[inputField],
        value,
      },
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    const validateOptions = {
      fieldsToValidate: fields,
    };
    const { areFieldsValid, updatedFields } = validateFields(validateOptions);
    // show error messages (if any)
    setFields({
      ...fields,
      ...updatedFields,
    });

    if (areFieldsValid) {
      const readyFields = serializeFields(fields);
      submitForm(readyFields);
    }
  };

  const submitForm = async (readyFields: Dict) => {
    try {
      await onSubmit({
        id: (verify && verify.id) || '',
        otp: readyFields.otp,
      });
      if(token) {
        history.replace(`/signup/onboard?token=${token}`)
      }

      else {
        history.replace('/signup/onboard');
      }
      
    } catch (error) {
      // console.log('Login error:', error);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setFields({
          ...fields,
          otp: {
            ...fields.otp,
            error: error.response.data.message,
          },
        });
      }
    }
  };

  const sendOTP = async () => {
    if (!verify) {
      toastr.error('Error', 'Unable to send! Please Try again!');
      return;
    }
    try {
      // console.log('sending otp to : ', this.props.verify.id);
      await sendOTPAction((verify && verify.id) || '');
      toastr.success('Resent OTP!', 'Please enter the new OTP to verify!');
    } catch (error) {
      toastr.error('Error Sending OTP!', 'Please rensed again!');
      // console.log('rend otp failed: ', error);
    }
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="Verification__content">
        <h4 className="Verification__heading">Verify your mobile number</h4>
        {verify && (
          <p className="Verification__text">
            We sent a six digit code to{' '}
            <span className="Verification__number">{verify.phoneNumber}</span>
          </p>
        )}
        <p className="Verification__text">
          Enter the code below to confirm your mobile number.
        </p>
      </div>

      <OTPInput
        onChange={(value) => getInput('otp', value)}
        error={fields.otp.error}
      />

      <div className="text-muted mb-4 py-2 d-flex justify-content-lg-start justify-content-center">
        <span className="mr-1">Did not receive OTP?</span>
        <Button type="button" size="xs" btnlink onClick={sendOTP}>
          Resend OTP
        </Button>
      </div>
      <div className="row justify-content-lg-start justify-content-center">
        <div className="col-lg-12 col-md-6 col-sm-8 col-12">
          <Button type="submit" disabled={isLoading} expand>
            Verify & Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

export default StepForm;
