import { PhoneNumberUtil } from 'google-libphonenumber';

function required(field: string, value: any, label: string) {
  const fieldLabel = label || field;
  // console.log('field: ', field, value, label);
  if (value === '' || value === undefined) {
    return `${fieldLabel} is required`;
  }
  return '';
}

function email(field: string, value: any, label: string) {
  const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  const fieldLabel = label || field;
  if (!reg.test(value)) {
    return `${fieldLabel} is invalid`;
  }
  return '';
}

function phone(field: string, value: any, label: string) {
  const fieldLabel = label || field;
  const phoneValidator = PhoneNumberUtil.getInstance();
  let valid = false;
  try {
    valid = phoneValidator.isValidNumber(phoneValidator.parse(`+${value}`));
  } catch (e) {
    // console.log(e);
  }

  if (!valid) {
    return `${fieldLabel} is invalid`;
  }
  return '';
}

function conditionalRequired(validateIf: boolean | (() => boolean)) {
  return (field: string, value: any, label: string) => {
    if (!validateIf) {
      return '';
    }
    if (typeof validateIf === 'function') {
      if (!validateIf()) {
        return '';
      }
    }

    const fieldLabel = label || field;
    // console.log('field: ', field, value, label);
    if (value === '' || value === undefined) {
      return `${fieldLabel} is required`;
    }
    return '';
  };
}

function equals<T>(checkValue: T, customErrorMessage?: string) {
  return (field: string, value: T, label: string) => {
    const fieldLabel = label || field;
    const valid = value === checkValue;
    if (!valid) {
      return customErrorMessage || `${fieldLabel} should be ${checkValue}`;
    }
    return '';
  };
}

function uppercaseCheck(field: string, value: any, label: string) {

    const fieldLabel = label || field;
    const uppercaseRegex = /[A-Z]/g; 
    if (!value.match(uppercaseRegex))  {
      return (
        `${fieldLabel} must be at least 1 uppercase character`
      );
    }
    return '';
}

function lowercaseCheck(field: string, value: any, label: string) {
    const fieldLabel = label || field;
    const lowercaseRegex = /[a-z]/g; 
    if (!value.match(lowercaseRegex))  {
      return (
        `${fieldLabel} must be at least 1 lowercase character`
      );
    }
    return '';
}

function numberCheck(field: string, value: any, label: string){

    const fieldLabel = label || field;
    const numbersRegex = /[0-9]/g;
  if (!value.match(numbersRegex)) {
      return (
        `${fieldLabel} must be at least 1 number`
      );
    }
    return '';
  
}

function characterCheck(field: string, value: any, label: string) {

    const fieldLabel = label || field;
    const characterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!value.match(characterRegex)) {
      return (
        `${fieldLabel} must be at least 1 special character`
      );
    }
    return '';
  
}


function regexPattern(pattern: RegExp, customErrorMessage?: string) {
  return (field: string, value: any, label: string) => {
    const fieldLabel = label || field;
    if (pattern && !pattern.test(value)) {
      return customErrorMessage || `${fieldLabel} is invalid`;
    }
    return '';
  };
}
function exactLength(requiredLength: number, customErrorMessage?: string) {
  return (field: string, value: any, label: string) => {
    const fieldLabel = label || field;
    if (!value || value.length !== requiredLength) {
      return (
        customErrorMessage ||
        `${fieldLabel} should have exact ${requiredLength} letters.`
      );
    }
    return '';
  };
}
function minLength(minimumLength: number, customErrorMessage?: string) {
  return (field: string, value: any, label: string) => {
    const fieldLabel = label || field;
    if (!value || value.length < minimumLength) {
      return (
        customErrorMessage ||
        `${fieldLabel} should have atleast ${minimumLength} characters.`
      );
    }
    return '';
  };
}

function minValue(minimumValue: number, customErrorMessage?: string) {
  return (field: string, value: any, label: string) => {
    const fieldLabel = label || field;
    if (!value || value < minimumValue) {
      return (
        customErrorMessage ||
        `${fieldLabel} should be greater than ${minimumValue}.`
      );
    }
    return '';
  };
}

function maxValue(maximumValue: number, customErrorMessage?: string) {
  return (field: string, value: any, label: string) => {
    const fieldLabel = label || field;
    if (!value || value > maximumValue) {
      return (
        customErrorMessage ||
        `${fieldLabel} should be less than ${maximumValue}.`
      );
    }
    return '';
  };
}

function passwordMatch(params:any) {
  if (
    params.value &&
    params.fields[params.matchingField].value &&
    params.value.toString() !==
      params.fields[params.matchingField].value.toString()
  ){
    return `Password Mismatch! Both passwords must be same!`;
  }
  return '';
}

// function date(customErrorMessage = undefined) {
//   return (field, value, label) => {
//     const fieldLabel = label || field;
//     if (pattern && pattern.test(value) === false)
//       return customErrorMessage || `${fieldLabel} is invalid`;
//     return '';
//   };
// }

export default {
  required,
  email,
  phone,
  conditionalRequired,
  equals,
  regexPattern,
  exactLength,
  minLength,
  minValue,
  maxValue,
  uppercaseCheck,
  lowercaseCheck,
  numberCheck,
  characterCheck,
  passwordMatch
};
