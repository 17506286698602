import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Button from '../../components/button';
import ThankYouImage from '../../assets/images/thankyou-img.png';
import { RouteKeys } from '../route-keys';
import { State } from '../../store/interfaces';
import { actions as authUserActions } from '../../store/reducers/authUser';

const ThankYouPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.authUser.user);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  const [rtaToken,setRtatoken] = useState(token)

  const fetchProfile = async (onSuccess?: () => void) => {
    
      await dispatch(authUserActions.fetchProfile(onSuccess));
    
  };

 useEffect(()=>{

  fetchProfile()
 },[rtaToken])



  useEffect(() => {
    if (loading || !token) return;
    if(user?.StudentCourse) {

    const amount = user?.StudentCourse?.amount?.totalAmountWithDiscount
     ? Math.round(user.StudentCourse.amount.totalAmountWithDiscount) : 0;
    const promoCoupon = user?.StudentCourse?.amount?.promotions?.[0]?.publicPromoCode || '';
    const studentId = user?.studentDetails?.studentId || '';

    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.src = 'https://www.sbm1j34k.com/scripts/sdk/everflow.js';

    const handleScriptLoad = () => {
      const script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.innerHTML = `
        EF.conversion({
          offer_id: 2,
          amount: ${amount},
          coupon_code: '${promoCoupon}',
          order_id: '${studentId}',
        });
      `;
      document.head.appendChild(script2);
    };

    script1.onload = handleScriptLoad;
    document.head.appendChild(script1);

    return () => {
      document.head.removeChild(script1);
    };
  }
  }, [user?.StudentCourse]);

  
  const goBackHome = async () => {
    setLoading(true);
    await fetchProfile(() => {
      
      history.replace(RouteKeys.Dashboard);
      setLoading(false);
    });
  };

  const renderImage = () => (
    <div className="Thankyou-img">
      <img src={ThankYouImage} className="img-fluid" alt="thankyou" />
    </div>
  );

  const renderHeader = () => (
    <h3 className="font-weight-bold text-uppercase text-primary pt-5 pb-2">
      Thank you
    </h3>
  );

  const renderDescription = () => (
    <p className="font-lg text-muted mb-5">
      <span className="mr-1 mb-1 d-sm-block">
        For registering with Excellence Driving Center.
      </span>
      We will contact you soon once our executives verified your details.
    </p>
  );

  const renderBackButton = () => (
    <Button
      type="button"
      className="Button Button--md"
      onClick={goBackHome}
      disabled={loading}
    >
      {loading ? 'Please wait' : 'Back to Home'}
    </Button>
  );

  const renderBody = () => (
    
    <div className="Thankyou text-center py-5 my-4">
      <div className="container">
         
        {renderImage()}
        {renderHeader()}
        {renderDescription()}
        {renderBackButton()}
      </div>
    </div>
  );
  
  return (
    <>
      <Header />
      {renderBody()}
      <Footer />
    </>
  );
};

export default ThankYouPage;
